import './AskMeAnything.scss';
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import {useAppContext} from "@/contexts";
import {ask, deletePrompt, savePrompt} from "@/api";
import {useClickOutsideHandler} from "@/hooks/useClickOutside.tsx";
import {Convo} from "@/components/InteractiveChat";
import {PromptInput} from "@/components/PromptInput";

export interface AskMeAnythingProps extends React.HTMLAttributes<HTMLDivElement> {
    onStartConvo?(convo: Convo): void;
}

const baseClassName = 'ask-me-anything';

const DEFAULT_FAVORITES: string[] = [
    `Show me lease agreements with covenants about hazardous materials.`,
    `Show me leases with Larry's Commercial Real Estate as the broker.`,
    `Show me sales agreements with only one location.`,
    `Show me contracts with home depot.`,
];

const DEFAULT_MESSAGE = 'Not sure where start? You can try:';

export function AskMeAnything({className, onStartConvo, ...props}: AskMeAnythingProps) {

    const {setSearchResults, savedPrompts, removeSavedPrompt, addSavedPrompt} = useAppContext();
    const [prompt, setPrompt] = useState<string>('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const [busy, setBusy] = useState<boolean>(false);
    const [response, setResponse] = useState<string | undefined>();
    const [overlayActive, setOverlayActive] = useState<boolean>(false);
    const processedPrompt = useRef<string>('');
    const favorites = useMemo<string[]>(() => {

        if (savedPrompts?.length) {
            return savedPrompts.map(v => v.text)
        }

        return DEFAULT_FAVORITES;
    }, [savedPrompts]);
    const isFavorite = favorites.includes(prompt);

    useClickOutsideHandler(overlayRef, () => {
        setOverlayActive(false);
        setBusy(false);
    })

    useEffect(() => {
        if (prompt !== processedPrompt.current) {
            setResponse(undefined);
        }
    }, [prompt]);

    function handleFavoriteQuestion(question: string): void {
        setPrompt(question);
        askQuestion(question);
    }

    function handleRunClick(prompt: string): void {
        setOverlayActive(true);
        askQuestion(prompt);
    }

    function handlePreviewRun(): void {
        setOverlayActive(true);
        askQuestion(prompt);
    }

    function startConvo() {

        if(response) {
            onStartConvo?.([
                {prompt: prompt},
                {type: 'answer', answer: response}
            ]);
            setOverlayActive(false);
        }
    }

    async function askQuestion(prompt: string): Promise<void> {
        if (prompt.trim()) {
            try {
                textareaRef.current?.focus();
                setBusy(true);
                setResponse(undefined);

                processedPrompt.current = prompt;
                const response = await ask(prompt);

                if (processedPrompt.current === prompt) {
                    if (response.type === 'answer') {
                        setResponse(response.answer);
                        setBusy(false);
                    } else {
                        onStartConvo?.([
                            {prompt: prompt},
                            response
                        ]);
                        setOverlayActive(false);
                    }
                }
            } catch (err) {
                if (processedPrompt.current === prompt) {
                    // todo: show a message explaining that we don't have answer
                    setResponse(`Sorry, I couldn't find an answer to your question.\nBut I'm constantly learning, so ask me again tomorrow.`)
                    setBusy(false);
                }
            }
        } else {
            setSearchResults(undefined);
        }
    }

    function handlePromptChange({target}: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        setPrompt(target.value);
    }

    function handlePreviewClick() {
        setOverlayActive(true);
        textareaRef.current?.focus();
    }

    function handlePreviewFavoriteToggle() {
        toggleFavorite(prompt);
    }

    function handleFavoriteToggle(prompt: string) {
        toggleFavorite(prompt);
    }

    async function toggleFavorite(prompt: string): Promise<void> {
        if (isFavorite) {
            const savedQuestion = savedPrompts?.find(q => q.text === prompt);
            if (savedQuestion) {
                removeSavedPrompt(savedQuestion);
                await deletePrompt(savedQuestion.id);
            }

        } else {
            if (prompt.trim()) {
                const savedQuestion = await savePrompt(prompt.trim());
                addSavedPrompt(savedQuestion);
            }
        }
    }

    function handleReset(): void {
        processedPrompt.current = ''
        setPrompt('');
        setResponse(undefined);
        setBusy(false);
        setSearchResults(undefined);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={classnames(`${baseClassName}__overlay`, {
            [`${baseClassName}__overlay--active`]: overlayActive
        })} ref={overlayRef}>
            <div className={`${baseClassName}__prompt-wrapper`}>
                <PromptInput value={prompt} dyRef={textareaRef} className={`${baseClassName}__prompt`}
                             includeFavorite={true}
                             isFavorite={isFavorite}
                             onChange={handlePromptChange}
                             onSubmit={handleRunClick}
                             onFavorite={handleFavoriteToggle}/>
                <DyTriggerIcon className={`${baseClassName}__reset-trigger`} icon={'refresh'}
                               tip={'Clear'}
                               onClick={handleReset}/>
            </div>
            {busy ? (
                <div className={classnames(`${baseClassName}__message`, `${baseClassName}__message--busy`)}>
                    Generating your results.
                </div>
            ) : response ? (<>
                <div className={`${baseClassName}__answer`}>
                    <h1 className={`${baseClassName}__answer-title`}>Doculy AI</h1>
                    <div className={`${baseClassName}__answer-text`}>
                        {response}
                    </div>
                </div>
                <div className={`${baseClassName}__start-convo`}>
                    <a onClick={startConvo}>Do you want to ask more?</a>
                </div>
            </>) : (<>
                {!savedPrompts?.length && (
                    <div className={classnames(`${baseClassName}__message`)}>
                        {DEFAULT_MESSAGE}
                    </div>
                )}
                <div className={`${baseClassName}__favorites`}>
                    {favorites.map(text => (
                        <div key={text}
                             role="button"
                             onClick={() => handleFavoriteQuestion(text)}
                             className={classnames(`${baseClassName}__favorite`)}>
                            {text}
                        </div>
                    ))}
                </div>
            </>)}
        </div>
        <div className={`${baseClassName}__preview-wrapper`}>
            <div className={classnames(`${baseClassName}__preview`, {
                [`${baseClassName}__preview--empty`]: !prompt
            })}>
                <div className={`${baseClassName}__text-wrapper`}>
                    <div className={`${baseClassName}__text`} onClick={handlePreviewClick}>
                        {prompt || 'Ask any document related question.'}
                    </div>
                </div>
                <div className={`${baseClassName}__actions`}>
                    <DyTriggerIcon
                        className={classnames(`${baseClassName}__action`, `${baseClassName}__action--favorite`)}
                        icon={isFavorite ? 'star-filled' : 'star'} onClick={handlePreviewFavoriteToggle}
                        tip={isFavorite ? 'Remove From Favorites' : 'Add To Favorites'}/>
                    <DyTriggerIcon className={classnames(`${baseClassName}__action`, `${baseClassName}__action--run`)}
                                   icon={'enter'} onClick={handlePreviewRun}/>
                </div>
            </div>
            <DyTriggerIcon className={`${baseClassName}__reset-trigger`} icon={'refresh'}
                           tip={'Clear'}
                           onClick={handleReset}/>
        </div>

    </div>
}

export default AskMeAnything;
