import './ConfidenceScore.scss';
import React from "react";
import classnames from "classnames";
import {DyDialog} from "@/components/core/DyDialog";
import {useConfidenceScore} from "@/components/popups/ConfidenceScore/useConfidenceScore.ts";

export interface ConfidenceScoreProps extends React.HTMLAttributes<HTMLDivElement> {
}

const baseClassName = 'confidence-score';

export function ConfidenceScore({className, ...props}: ConfidenceScoreProps) {

    const {close} = useConfidenceScore();

    return <DyDialog className={classnames(baseClassName, className)} {...props}>
        <DyDialog.Header onClose={close}>Confidence Scores</DyDialog.Header>
        <DyDialog.Message>
            <p>The <b>Confidence Scores</b> are applied to indicate the reliability of the data extracted from a contract using
                AI. Here's a short explanation for each confidence score:</p>
        </DyDialog.Message>
        <DyDialog.Content className={`${baseClassName}__scores`}>
            <dl className={classnames(`${baseClassName}__score`, `${baseClassName}__score--100`)}>
                <dl className={`${baseClassName}__title`}>100% confidence</dl>
                <dt className={`${baseClassName}__description`}>
                    The information extracted is considered highly reliable and can be used with utmost confidence for
                    decision-making or further processing.
                </dt>
            </dl>
            <dl className={classnames(`${baseClassName}__score`, `${baseClassName}__score--90`)}>
                <dl className={`${baseClassName}__title`}>More than 90% confidence</dl>
                <dt className={`${baseClassName}__description`}>
                    The information extracted is considered suitable for critical tasks but may warrant additional
                    verification for absolute certainty.
                </dt>
            </dl>

            <dl className={classnames(`${baseClassName}__score`, `${baseClassName}__score--75`)}>
                <dl className={`${baseClassName}__title`}>More than 70% confidence</dl>
                <dt className={`${baseClassName}__description`}>
                    There is a notable margin for potential error. Consideration should be given to the nature of the
                    task and whether additional validation is necessary.
                </dt>
            </dl>

            <dl className={classnames(`${baseClassName}__score`, `${baseClassName}__score--50`)}>
                <dl className={`${baseClassName}__title`}>Less than 70%</dl>
                <dt className={`${baseClassName}__description`}>
                    Additional verification or manual review is recommended to ensure the accuracy of the data before
                    making decisions based on it.
                </dt>
            </dl>
        </DyDialog.Content>
    </DyDialog>
}

export function ConfidenceScoreLink(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {

    const {open} = useConfidenceScore();
    return <a {...props} onClick={open}/>
}

export default ConfidenceScore;
