import {AggregatedField, PreparedField} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";
import {snakeCase, uniq} from "lodash";

export function groupAndPrepareFields({fields, docType}: DoculyDoc): Record<string, AggregatedField> {

    const docConfig = window.docTypeConfigs.get(docType) ?? {fields: []};
    const fieldKeys = uniq(fields.map(field => field.key));

    return fieldKeys.reduce((map, key) => {

        const values = fields.filter(field => field.key === key).sort((a, b) => (a.srcPositions?.[0]?.y ?? 0) - (b.srcPositions?.[0]?.y ?? 0))
        const [first] = values;
        const {config} = docConfig.fields.find(fc => fc.key === key) ?? {}
        return {
            ...map,
            // todo: fix keys so they're always snake-case in the db and ui, not sure why it was changed
            [snakeCase(key)]: {
                displayName: first.displayName,
                datatype: first.datatype,
                config,
                values
            }
        }
    }, {});
}

export function generatePreparedFields({fields, docType}: DoculyDoc): PreparedField[] {

    const docConfig = window.docTypeConfigs.get(docType);
    if (!docConfig) return fields;

    const aggregates: Record<string, PreparedField> = docConfig.fields
        .filter(field => field.config.aggregate)
        .reduce((map, field) => {

            const value = fields.filter(f => f.key === field.key);

            if (!value.length) return map;

            return {
                ...map,
                [field.key]: {
                    displayName: field.displayName,
                    datatype: value[0]?.datatype,
                    config: field.config,
                    value,
                }
            }
        }, {});

    // return an ordered array of fields where the field is either array from the aggregates
    // or one or more single values for non-aggregates.
    // we use an array of arrays to for aggregates so that they remain array even after calling
    // flat
    const result: PreparedField[] = uniq(docConfig.fieldOrder).map(field => {

        if (aggregates[field]) {
            return [aggregates[field]]
        }

        const fieldConfig = docConfig.fields.find(fc => fc.key === field)
        return fields.filter(f => f.key === field).map(value => ({
            displayName: value.displayName,
            config: fieldConfig?.config,
            datatype: value.datatype,
            value
        }))
    }).flat();

    return result;
}
