import './PendingApproval.scss';
import React from "react";
import classnames from "classnames";
import {DyDialog} from "@/components/core/DyDialog";
import {DyButton} from "@/components/core";
import {useAppContext} from "@/contexts";
import {tenantAuth} from "@/services/auth.service.ts";

export interface PendingApprovalProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'pending-approval';

export function PendingApproval({className, ...props}: PendingApprovalProps) {

    const {user} = useAppContext();

    function handleLogout() {
        tenantAuth.signOut()
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DyDialog className={classnames(baseClassName, className)} {...props}>
            <DyDialog.LogoHeader />
            <DyDialog.Content className={classnames(`${baseClassName}__content`)}>
                <p>{user?.firstName}, your account is currently pending approval.</p>
                <p>Feel free to wait here or we'll notify you as soon as your accounts ready to go.</p>
            </DyDialog.Content>
            <DyDialog.Actions className={`${baseClassName}__actions`}>
                <DyButton onClick={handleLogout}>
                    Log Out
                </DyButton>
            </DyDialog.Actions>
        </DyDialog>
    </div>
}

export default PendingApproval;
