import './DocumentFieldValueTable.scss';
import React, {CSSProperties} from "react";
import classnames from "classnames";
import {capitalCase} from "change-case";
import {camelCase, snakeCase} from "lodash";
import {formatDateRange, formatValue} from "@/utils/formatter.ts";
import {FieldConfig} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";

export interface DocumentFieldValueTableProps {
    className?: string;
    fields: DoculyField<Record<string, DoculyField>>[];
    config: FieldConfig;
}

const baseClassName = 'document-field-value-table';

export function DocumentFieldValueTable({className, fields, config}: DocumentFieldValueTableProps) {

    const {columns = [], format: formats = {}} = config;
    return <div className={classnames(baseClassName, className)} style={{
        '--column-count': columns.length
    } as CSSProperties}>
        {columns.map(column => <div className={`${baseClassName}__header`}>
            {capitalCase(column)}
        </div>)}
        {fields?.map?.(field => columns.map(column => {
            const key = camelCase(column);
            const format = formats[key];
            const value = formatValue(field.value[key], format);

            return <div className={classnames(`${baseClassName}__value`, {
                [`${baseClassName}__value--${format}`]: format
            })}>
                {value}
            </div>
        }))}
    </div>
}

export default DocumentFieldValueTable;
