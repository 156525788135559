import {format} from 'date-fns';

export type FormatType =
    'date'
    | 'datetime'
    | 'date_range'
    | 'datetime_range'
    | 'money'
    | 'percent'
    | 'number'
    | 'text';

const dateOnlyRegex = new RegExp(/^\d{4}-[01]\d-[0-3]\d$/);
const isoDateRegex = new RegExp(/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d(\.\d+)?)?([+-][0-2]\d:[0-5]\d|Z)$/);
const timeSuffixRegex = new RegExp(/T.*$/);

export function isIsoDate(value: any): boolean {
    return typeof value === 'string' && isoDateRegex.test(String(value));
}

export function isDateOnly(value: any): boolean {
    return typeof value === 'string' && dateOnlyRegex.test(String(value));
}

export function isDateLike(value: any): boolean {

    return value instanceof Date || isIsoDate(value) || isDateOnly(value);
}

export function isRangeLike(value: any): boolean {
    return Array.isArray(value) && value.length === 2;
}

export function isDateRange(value: any): boolean {
    return isRangeLike(value) && isDateLike(value[0]) && isDateLike(value[1])
}

export function formatDate(value: any): string {
    if (!isDateLike(value)) {
        return String(value);
    }

    try {
        return format(`${value.replace(timeSuffixRegex, '')}T00:00`, 'MMMM d, yyyy')
    } catch (err) {
        console.warn(err);
        return value;
    }
}

export function formatDateRange(value: any): string {

    if (isDateRange(value)) {
        return `${formatDate(value[0])} to ${formatDate(value[1])}`;
    }

    if (isDateLike(value)) {
        return formatDate(value);
    }

    if (isRangeLike(value)) {
        return `${String(value[0])} to ${String(value[1])}`
    }

    return String(value ?? '');
}


export function formatValue(value: any, format: string = 'string'): string {

    switch (format) {
        case 'date':
        case 'daterange':
            return formatDateRange(value);
        case 'number':
            return isNumber(value) ? new Intl.NumberFormat().format(value) : String(value ?? '');
        case 'money':
            return isNumber(value) ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(value) : String(value ?? '');
        case 'percent':
            return isNumber(value) ? `${value * 100}%` : String(value ?? '');
        case 'string':
        default:
            return String(value ?? '');
    }
}

function isNumber(value: any): boolean {
    return value !== null && value !== undefined && value.trim?.() !== '' && !isNaN(value);
}
