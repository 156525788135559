import './WizardFinalStep.scss';
import React, {useRef} from "react";
import classnames from "classnames";
import {DyDialog} from "@/components/core/DyDialog";
import {DyButton} from "@/components/core";
import Excel from 'exceljs'
import {extractDocumentFields} from "@/api";
import {useSearchParams} from "react-router-dom";
import saveAs from 'file-saver';
import {capitalCase} from "change-case";
import {map} from "lodash";

export interface WizardFinalStepProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose?(): void;
    onStartOver?(): void;
}

const baseClassName = 'wizard-final-step';

export function WizardFinalStep({className, onStartOver, onClose, ...props}: WizardFinalStepProps) {

    const [searchParams] = useSearchParams();
    const cachedExtracts = useRef<any[]>();

    async function handleExportExcel(): Promise<void> {

        const workbook = await generateWorkbook();

        saveAs(new Blob([await workbook.xlsx.writeBuffer()], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), 'extract.xlsx')
    }

    async function handleExportCsv(): Promise<void> {

        const workbook = await generateWorkbook();

        saveAs(new Blob([await workbook.csv.writeBuffer()], {
            type: "text/csv"
        }), 'extract.csv')
    }

    function handleStartOver() {
        onStartOver?.();
    }

    async function generateWorkbook(): Promise<Excel.Workbook> {

        let extracts = cachedExtracts.current;
        if(!extracts) {

            const rawExtracts  = await Promise.all(searchParams.getAll('ids').map(id => extractDocumentFields(id)));
            extracts = cachedExtracts.current = rawExtracts.map(({fields, documentType, fileName}) => {

                // keep a consistent set of suffixes for each field name
                // this allows us to handle repeating fields across multiple documents in a consistent way
                const ctrs: Record<string, number> = {};

                return {
                    documentType,
                    fileName,
                    fields: fields.reduce((fieldMap, {displayName, rawValue, datatype}) => {

                        ctrs[displayName] = ctrs[displayName] ?? 0;

                        return {
                            ...fieldMap,
                            [`${displayName}#${ctrs[displayName]++}`]: {
                                type: datatype,
                                value: typeof rawValue === 'string' ? rawValue : map(rawValue, (value, key) => `${capitalCase(key)}: ${value.rawValue}`).join('\r\n')
                            }
                        }
                    }, {})
                }
            });
        }
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Doculy Extract');

        // get the unique set of fields from all documents
        const allFields = [...new Set(...extracts.map(extract => Object.keys(extract.fields)))]

        worksheet.columns = [
            {header: 'File'},
            {header: 'Document Type'},
            ...allFields.map(field => ({
                header: capitalCase(field.replace(/#.*$/, '')),
                alignment: {
                    wrapText: true
                }
            }))
        ]
        worksheet.addRows(extracts.map(extract => [
            extract.fileName,
            extract.documentType,
            ...allFields.map(field => extract.fields[field]?.value)
        ]));
        autoExpandColumnWidths(worksheet, 100);

        return workbook;
    }

    return <DyDialog className={classnames(baseClassName, className)} {...props}>
        <DyDialog.LogoHeader onClose={onClose}/>
        <DyDialog.Message>
            ⚡️ Please download the extracted data or send it to your contract repository for search and analytics!
        </DyDialog.Message>
        <DyDialog.Actions>
            <DyButton onClick={handleExportExcel}>Download Excel File</DyButton>
            <DyButton onClick={handleExportCsv}>Download CSV File</DyButton>
            {/*<DyButton onClick={onClose} dyType={'dark'}>Send to Contact Repository</DyButton>*/}
            <DyButton onClick={handleStartOver} dyType={'secondary'}>Start Over</DyButton>
        </DyDialog.Actions>
    </DyDialog>
}

function autoExpandColumnWidths(worksheet, maxWidth: number = Infinity) {
    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        column.width = Math.min(maxWidth, Math.max(...lengths.filter(v => typeof v === 'number')));
        column.alignment = {
            ...column.alignment,
            wrapText: true,
            vertical: 'top'
        };
    });
}

export default WizardFinalStep;
