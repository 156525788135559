import {defaultAuthenticatedApiClient} from "@/api/ApiClient.ts";

export interface TenantFileUploadInfo {
    id: number;
    guid: string;
    uploadUrl: string;
    filename: string;
}
export type UrlMap = Record<string, TenantFileUploadInfo>;

export async function getUploadUrls(files: File[]): Promise<UrlMap> {

    return defaultAuthenticatedApiClient.httpPost<UrlMap>('/files.generate_upload_urls', {
        files: files.map(file => ({
            name: file.name,
            type: file.type
        }))
    });
}

/**
 * Generates a signed download url for a document
 * @param id document id from the clause_library.document table.
 */
export async function getDownloadUrl(id: number): Promise<string> {
    return defaultAuthenticatedApiClient.httpGet<string>(`/documents/${id}/download_url`);
}
