import './PendingEmailVerification.scss';
import React from "react";
import classnames from "classnames";
import {DyButton} from "@/components/core";
import {DyDialog} from "@/components/core/DyDialog";
import {useAppContext} from "@/contexts";
import {resendVerificationEmail} from '@/api';
import {toast} from "react-toastify";
import {dyAlert} from '@/components/core/DyModal'
import {tenantAuth} from "@/services/auth.service.ts";

export interface PendingEmailVerificationProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'pending-email-verification';

export function PendingEmailVerification({className, ...props}: PendingEmailVerificationProps) {

    const {fbUser} = useAppContext();

    async function handleResend() {
        try {
            await resendVerificationEmail();
            toast('Your verification has been sent.');
        } catch(err) {
            dyAlert({
                title: 'Failed To Send',
                content: <>
                    <p>We were unable to resend your verification email.</p>
                </>
            })
        }
    }

    function handleLogout() {
        tenantAuth.signOut()
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DyDialog className={classnames(baseClassName, className)} {...props}>
            <DyDialog.LogoHeader />
            <DyDialog.Content className={classnames(`${baseClassName}__content`)}>
                <p>Please verify your email address by clicking the link in the email we sent to <span className={`${baseClassName}__email`}>
                    {fbUser?.email}.
                    </span></p>
                <p>If you did not receive the email please check your spam folder or click the button below.</p>
            </DyDialog.Content>
            <DyDialog.Actions className={`${baseClassName}__actions`}>
                <DyButton className={`${baseClassName}__action--done`} onClick={handleResend}>
                    Resend Verification Email
                </DyButton>
                <DyButton onClick={handleLogout}>
                    Log Out
                </DyButton>
            </DyDialog.Actions>
        </DyDialog>
    </div>
}

export default PendingEmailVerification;
