import {openModal} from "@/components/core/DyModal";
import React, {ChangeEvent} from "react";
import {InvitationCreatorRef} from "@/components/InvitationCreator/InvitationCreator.tsx";
import InvitationViewer from "@/components/InvitationViewer/InvitationViewer.tsx";
import {DyTextInput} from "@/components/core";
import {clone} from "lodash";
import {validate} from 'email-validator';
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import {sendInvitations} from "@/api";


const baseClassName = 'invitation-creator';

const BLANK_INVITATION: InvitationParams = {email: '', firstName: '', lastName: ''};

export const openInvitationCreator = () => openModal<{ invitations: InvitationParams[] }, InvitationCreatorRef>({
    className: baseClassName,
    title: 'Invite Team Members',
    closable: false,
    initialState: {
        invitations: [clone(BLANK_INVITATION)]
    },
    content({state, setState}) {

        function handleChange(e: ChangeEvent<HTMLInputElement>, index: number): void {

            const {name, value} = e.target;
            setState(prevState => ({
                ...prevState,
                invitations: prevState.invitations.map((v, i) => i !== index ? v : {
                    ...v,
                    [name]: value
                })
            }))
        }

        function handleDelete(index: number): void {
            setState(prevState => ({
                ...prevState,
                invitations: prevState.invitations.filter((_, i) => i !== index)
            }))
        }

        return <div className={`${baseClassName}__invitations`}>
            {state.invitations.map((invitation, index) => (
                <div key={index} className={`${baseClassName}__invitation`}>
                    <DyTextInput label={index === 0 ? 'Email' : ''} name={'email'}
                                 value={invitation.email}
                                 onChange={e => handleChange(e, index)}/>
                    <DyTextInput label={index === 0 ? 'First Name' : ''} name={'firstName'}
                                 value={invitation.firstName}
                                 onChange={e => handleChange(e, index)}/>
                    <DyTextInput label={index === 0 ? 'Last Name' : ''} name={'lastName'}
                                 value={invitation.lastName}
                                 onChange={e => handleChange(e, index)}/>
                    {state.invitations.length > 1 && (
                        <DyTriggerIcon icon={'delete'} onClick={() => handleDelete(index)}/>
                    )}
                </div>
            ))}
        </div>
    },
    actions: [{
        key: 'cancel',
        label: 'Cancel',
        dyType: 'secondary',
        className: `${baseClassName}__cancel`,
        action({reject}): void {
            reject()
        }
    }, {
        key: 'add',
        label: 'Add Invitation',
        action({setState}): void {
            setState(prevState => ({
                invitations: [...prevState.invitations, clone(BLANK_INVITATION)]
            }));
        }
    }, {
        key: 'send',
        label: 'Send Invitations',
        disableIf({state}): boolean {
            const {invitations} = state;
            return !invitations.length || invitations.some(v => !validate(v.email) || !v.firstName.trim() || !v.lastName.trim());
        },
        async action({resolve, state}): Promise<void> {
            await sendInvitations(state.invitations);
            resolve()
        }
    }]
});
