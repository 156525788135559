import React, {ReactNode, useState} from "react";
import {getUploadUrls} from "@/api/files.api.ts";
import {uploadFile} from "@/services/file.service.ts";
import {FileUpload, FileUploadContext, FileUploadContextValue} from "./FileUploadContext";

export interface FileUploadProviderProps {
    children: ReactNode | ReactNode[];
}
export function FileUploadProvider({children}: FileUploadProviderProps) {

    const [value, setValue] = useState<FileUploadContextValue>({
        uploads: [],
        complete: false,
        async addUploads(files: File[]): Promise<void> {

            if (!files.length) return;

            const newUploads: FileUpload[] = files.map(file => ({
                file,
                progress: 0,
            }));

            setValue(({uploads, ...value}) => ({
                ...value,
                uploads: [...newUploads, ...uploads]
            }));

            const urlMap = await getUploadUrls(files);

            setValue(({uploads, ...value}) => ({
                ...value,
                uploads: uploads.map(upload => urlMap[upload.file.name] ? {
                    ...upload,
                    ...urlMap[upload.file.name]
                }: upload)
            }));

            newUploads.map(upload => {

                const {uploadUrl} = urlMap[upload.file.name]!
                uploadFile({
                    url: uploadUrl!,
                    file: upload.file,
                    onUploadProgress({progress = 0}) {
                        setValue(({uploads, ...value}) => {
                            uploads = uploads.map(u => upload.file === u.file ? {
                                ...u,
                                progress
                            } : u);
                            const complete = uploads.every(u => u.progress === 1);

                            return {
                                ...value,
                                uploads,
                                complete
                            }
                        })
                    }
                })
            });
        },
        async removeUpload(file: File): Promise<void> {

            setValue(({uploads, ...value}) => {

                uploads = uploads.filter(upload => upload.file !== file);
                const complete = uploads.every(u => u.progress === 1);

                return {
                    ...value,
                    uploads,
                    complete
                }
            })
        }
    })

    return (
        <FileUploadContext.Provider value={value}>
            {children}
        </FileUploadContext.Provider>
    );
}
