import './DySwitch.scss';
import React, {ReactNode} from "react";
import classnames from "classnames";

export interface DySwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: ReactNode;
    children?: ReactNode | ReactNode[];
}

const baseClassName = 'dy-switch';

export function DySwitch({className, children, label = children, type, ...props}: DySwitchProps) {

    return <label className={classnames(baseClassName, className)}>
        <input className={`${baseClassName}__input`} type={"checkbox"} {...props}/>
        <div className={`${baseClassName}__switch`}>
            <div className={`${baseClassName}__toggle`}/>
        </div>
        {label && <div className={`${baseClassName}__label-text`}>{label}</div>}
    </label>
}

export default DySwitch;
