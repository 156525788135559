import './InteractiveChat.scss';
import React, {ChangeEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import classnames from "classnames";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import DoculyAnswer from "@/components/InteractiveChat/DoculyAnswer.tsx";
import UserMessage from "@/components/InteractiveChat/UserMessage.tsx";
import {Convo, UserPrompt} from "@/components/InteractiveChat/InteractiveChat.types.ts";
import {ask, DoculyResult} from "@/api";
import {PromptInput} from "@/components/PromptInput";
import {useLocation, useNavigate} from "react-router-dom";

export interface InteractiveChatProps extends React.HTMLAttributes<HTMLDivElement> {
    initialConvo?: Convo;
}

const baseClassName = 'interactive-chat';

export function InteractiveChat({className, ...props}: InteractiveChatProps) {

    const {state} = useLocation();
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState<string>('');
    const [convo, setConvo] = useState<Convo>([]);
    const [thinking, setThinking] = useState<boolean>(false);
    const msgContainerRef = useRef<HTMLDivElement>(null);
    const firstMsgRef = useRef<HTMLDivElement>(null);
    const lastMsgRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        scrollToEnd();
    }, [convo]);

    useEffect(() => {
        setConvo(convo => [...convo, ...state]);
    }, [state])

    function scrollToStart() {
        firstMsgRef.current?.scrollIntoView({behavior: "smooth", block: 'end'})
    }

    function scrollToEnd() {
        lastMsgRef.current?.scrollIntoView({behavior: "smooth", block: 'start'});
    }

    function handlePromptChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        setPrompt(e.target.value);
    }

    async function handleSubmit(prompt: string) {
        if (thinking) return;

        try {
            setConvo(convo => [...convo, {prompt}]);
            setPrompt('');
            setThinking(true);
            // need to cancel this request if reset is clicked
            const response = await ask(prompt);
            setConvo(convo => [...convo, response]);
        } catch (err) {
            setConvo(convo => [...convo, {type: 'answer', answer: `Sorry, I can't help you with that right now`}]);
        } finally {
            setThinking(false);
        }
    }

    function handleExit() {
        navigate(-1);
    }

    function handleReset() {
        setConvo([]);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__chat`}>
            <header className={`${baseClassName}__header`}>
                <div className={`${baseClassName}__actions`}>
                    <DyTriggerIcon icon={'close'} className={`${baseClassName}__trigger`} onClick={handleExit}
                    tip={'Close Chat'}/>
                    <DyTriggerIcon icon={'up'} className={`${baseClassName}__trigger`} onClick={scrollToStart}
                                   tip={'Scroll To Top'}/>
                    <DyTriggerIcon icon={'down'} className={`${baseClassName}__trigger`} onClick={scrollToEnd}
                                   tip={'Scroll To Bottom'}/>
                </div>
                <div className={`${baseClassName}__tools`}>
                    {/*<DyTriggerIcon icon={'archive'} className={`${baseClassName}__trigger`}/>*/}
                    {/*<DyTriggerIcon icon={'export'} className={`${baseClassName}__trigger`}/>*/}
                    <DyTriggerIcon icon={'reset'} className={`${baseClassName}__trigger`}
                    tip={'Start A New Chat'}
                                   onClick={handleReset}/>
                </div>
            </header>
            <main className={`${baseClassName}__messages`} ref={msgContainerRef}>
                {convo.map((message, index) => {

                    const ref = index === 0 ? firstMsgRef : index === convo.length - 1 ? lastMsgRef : undefined;
                    if ((message as UserPrompt).prompt) {
                        return <UserMessage key={index}
                                            dyRef={ref}
                                            className={`${baseClassName}__message`} prompt={message as UserPrompt}/>
                    } else if ((message as DoculyResult).answer) {
                        return <DoculyAnswer key={index}
                                             dyRef={ref}
                                             className={classnames(`${baseClassName}__message`)}
                                             response={message as DoculyResult}/>
                    } else if ((message as DoculyResult).documents?.length) {
                        return <DoculyAnswer key={index}
                                             dyRef={ref}
                                             className={classnames(`${baseClassName}__message`, `${baseClassName}__message--with-docs`)}
                                             response={message as DoculyResult}/>
                    } else {
                        return <DoculyAnswer key={index}
                                             dyRef={ref}
                                             className={classnames(`${baseClassName}__message`)}
                                             response={{
                                                 type: 'answer',
                                                 answer: `Sorry, I'm not sure how to respond.  I'm still learning and getting better everyday.`
                                             }}/>
                    }
                })}
                {thinking && (
                    <DoculyAnswer response={{type: 'answer', answer: 'Let me see what I can find.'}}/>
                )}
            </main>
            <footer className={`${baseClassName}__footer`}>
                <PromptInput className={`${baseClassName}__prompt`}
                             onSubmit={handleSubmit}
                             value={prompt} onChange={handlePromptChange}/>
            </footer>
        </div>
    </div>
}

export default InteractiveChat;
