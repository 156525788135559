import './PdfReviewer.scss';
import React, {ChangeEvent, CSSProperties, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {Document, Page, } from "react-pdf";
import {SourcePosition} from "@/api";
import type {PDFDocumentProxy} from "pdfjs-dist";

export interface PdfReviewerProps extends React.HTMLAttributes<HTMLDivElement> {
    highlights?: SourcePosition[];
    file?: File | string;
}

const baseClassName = 'pdf-reviewer';

type PercentString = string;
type HighlightBounds = {
    top: PercentString;
    left: PercentString;
    width: PercentString;
    height: PercentString;
}

export function PdfReviewer({className, highlights, file, ...props}: PdfReviewerProps) {

    const [scale, setScale] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const highlightBoxes = useMemo<HighlightBounds[]>(() => {

        if(!highlights) return [];

        return highlights.filter(h => h.page + 1 === page).map(({x, y, height, width}) => ({
            top: `${y * 100}%`,
            left: `${x * 100}%`,
            width: `${Math.abs(width) * 100}%`,
            height: `${Math.abs(height) * 100}%`,
        }))
    }, [page, highlights]);

    useEffect(() => {
        if(highlights?.length) {
            setPage(highlights[0].page + 1);
        }
    }, [highlights])


    function handleScaleChange({target}: ChangeEvent<HTMLInputElement>): void {
        setScale(Number(target.value));
    }

    function handlePdfLoaded(document: PDFDocumentProxy): void {

        setTotalPages(document.numPages);
    }

    function changePage(inc: number) {
        setPage(page => Math.min(totalPages, Math.max(1, page + inc)))
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <main className={`${baseClassName}__content`}>
            <Document className={`${baseClassName}__document`}
                      file={file}
                      onLoadSuccess={handlePdfLoaded}>
                {Boolean(page) && (
                    <div className={`${baseClassName}__page-wrapper`}>
                        <Page scale={scale} pageNumber={page} className={`${baseClassName}__page`}/>
                        {highlightBoxes.map(box => (
                            <div className={`${baseClassName}__highlighter`} style={box}/>
                        ))}
                    </div>
                )}
            </Document>
        </main>
        <footer className={`${baseClassName}__footer`}>
            <div className={`${baseClassName}__page-manager`}>
                <div className={classnames(`${baseClassName}__prev-page`, {
                    [`${baseClassName}__prev-page--disabled`]: page === 1
                })}
                     onClick={() => changePage(-1)}/>
                <div className={`${baseClassName}__page`}>{page} of {totalPages}</div>
                <div className={classnames(`${baseClassName}__next-page`, {
                    [`${baseClassName}__next-page--disabled`]: page === totalPages
                })}
                     onClick={() => changePage(1)}/>
            </div>

            <input className={`${baseClassName}__zoom`} type={'range'} min={.5} max={3} step={.25}
                   value={scale} onChange={handleScaleChange}/>
        </footer>
    </div>
}

export default PdfReviewer;
