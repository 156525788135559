import './DyDialog.scss';
import React from "react";
import classnames from "classnames";
import {DoculyHeader, DoculyHeaderProps} from "@/components/DoculyHeader";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface DyDialogProps extends React.HTMLAttributes<HTMLDivElement> {
}

const baseClassName = 'dy-dialog';

export function DyDialog({className, children, ...props}: DyDialogProps) {
    return <div className={classnames(`${baseClassName}__wrapper`, className)} {...props}>
        <div className={baseClassName}>
            {children}
        </div>
    </div>
}

DyDialog.displayName = 'DyDialog';

DyDialog.Header = function({className, children, onClose, ...props}: DoculyHeaderProps) {
    return <header className={classnames(`${baseClassName}__header`, className)} {...props}>
        <DyTriggerIcon icon={'back'}  className={`${baseClassName}__back`} onClick={onClose}/>
        <h1 className={`${baseClassName}__title`}>{children}</h1>
        <DyTriggerIcon icon={'close'} className={`${baseClassName}__close-trigger`} onClick={onClose}/>
    </header>
}

DyDialog.LogoHeader = function({className, ...props}: DoculyHeaderProps) {
    return <DoculyHeader className={classnames(`${baseClassName}__logo-header`, className)} {...props} />
}

export interface DyDialogMessageProps extends React.HTMLAttributes<HTMLDivElement> {
}

DyDialog.Message = function ({className, children, ...props}: DyDialogMessageProps) {
    return <div className={classnames(`${baseClassName}__message`, className)} {...props}>
        {children}
    </div>
}

export interface DyDialogContentProps extends React.HTMLAttributes<HTMLDivElement> {
}

DyDialog.Content = function ({className, children, ...props}: DyDialogContentProps) {
    return <main className={classnames(`${baseClassName}__content`, className)} {...props}>
        {children}
    </main>
}

export interface DyDialogActionsProps extends React.HTMLAttributes<HTMLDivElement> {
}

DyDialog.Actions = function ({className, children, ...props}: DyDialogActionsProps) {
    return <footer className={classnames(`${baseClassName}__actions`, className)} {...props}>
        {children}
    </footer>
}


export default DyDialog;
