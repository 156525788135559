import './PopupContainer.scss';
import React from "react";
import {useSearchParams} from "react-router-dom";
import {ConfidenceScore} from './ConfidenceScore';

export function PopupContainer() {

    const [searchParams] = useSearchParams();

    switch (searchParams.get('popup')) {
        case 'score_definitions':
            return <ConfidenceScore className={`popup`}/>
        default:
            return <></>
    }

}

export default PopupContainer;
