import './PromptInput.scss';
import React, {KeyboardEvent} from "react";
import classnames from "classnames";
import AutoSizeTextArea from "react-textarea-autosize";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface PromptInputProps {
    className?: string;
    placeholder?: string;
    includeFavorite?: boolean;
    isFavorite?: boolean;
    value: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;

    onSubmit?(value: string): void;

    onFavorite?(value: string): void;

    dyRef?: React.Ref<HTMLTextAreaElement>;
}

const baseClassName = 'prompt-input';

export function PromptInput({
                                className,
                                placeholder,
                                value,
                                onChange,
                                onSubmit,
                                includeFavorite,
                                isFavorite = false,
                                onFavorite,
                                dyRef,
                                ...props
                            }: PromptInputProps) {

    async function detectEnter(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if(value?.trim()) {
                onSubmit?.(value);
            }
        }
    }

    function handleSubmit() {
        if(value?.trim()) {
            onSubmit?.(value);
        }
    }

    function handleFavorite() {
        if(value?.trim()) {
            onFavorite?.(value);
        }
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__prompt`}>
            <AutoSizeTextArea value={value} placeholder={placeholder}
                              onChange={onChange} onKeyDown={detectEnter} ref={dyRef}/>
        </div>
        <div className={`${baseClassName}__actions`}>
            {includeFavorite && (
                <DyTriggerIcon
                    className={classnames(`${baseClassName}__action`, `${baseClassName}__action--favorite`)}
                    icon={isFavorite ? 'star-filled' : 'star'} onClick={handleFavorite}
                    tip={isFavorite ? 'Remove From Favorites' : 'Add To Favorites' }/>
            )}
            <DyTriggerIcon
                className={classnames(`${baseClassName}__action`, `${baseClassName}__action--run`)}
                icon={'enter'} onClick={handleSubmit}/>
        </div>
    </div>
}

export default PromptInput;
