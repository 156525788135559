import {useSearchParams} from "react-router-dom";
import {DocumentImporter} from "@/components/importWizard/DocumentImporter";
import {WizardActionSelector} from "./WizardActionSelector";
import {FileUploadProvider} from "./FileUploadMonitor/FileUploadProvider";
import {WizardFinalStep} from "@/components/importWizard/WizardFinalStep";
import {dyConfirm} from "@/components/core/DyModal";
import {subscribeToNotifications} from "@/services";
import React from "react";

export function Wizard() {

    const [searchParams, setSearchParams] = useSearchParams();

    async function handleClose(): Promise<void> {

        if (Notification.permission === 'default' &&
            await dyConfirm({
                title: 'Get Notified',
                content: <>
                    <p>Enable push notifications and we'll let you know once your files have finished processing.</p>
                </>,
                yesLabel: 'Yes, Enable',
                noLabel: 'No Thanks'
            }) &&
            await Notification.requestPermission() === 'granted'
        ) {
            try {
                subscribeToNotifications();
            } catch(err) {
                // do nothing
            }
        }
        setSearchParams({}, {replace: true});
    }

    function handleChoice(choice: string): void {
        setSearchParams({wizardStep: choice}, {replace: true});
    }

    function handleImportComplete(importedIds: string[]): void {
        setSearchParams({
            wizardStep: 'extract',
            ids: importedIds
        }, {replace: true});
    }
    function handleStartOver() {
        setSearchParams({
            wizardStep: 'choose',
        }, {replace: true});
    }

    switch (searchParams.get('wizardStep')) {
        case 'choose':
            return <WizardActionSelector onClose={handleClose} onChoice={handleChoice}/>
        case 'import':
            return <FileUploadProvider>
                <DocumentImporter onClose={handleClose} onComplete={handleImportComplete}/>
            </FileUploadProvider>
         case 'download':
            return <WizardFinalStep onClose={handleClose} onStartOver={handleStartOver}/>
        default:
            return <></>
    }
}
