import React, {ReactNode, useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";

export type AppMessage = {
    message?: string;
    title?: string;
    data?: any;
    eventType?: string;
}

export interface MessagesContextValue {
    messages: AppMessage[];
}

export const MessagesContext = React.createContext<MessagesContextValue>({messages: []});

export interface MessagesProviderProps {
    children: ReactNode | ReactNode[];
}

export function MessagesProvider({children}: MessagesProviderProps) {

    const [value, setValue] = useState<MessagesContextValue>({
        messages: []
    });

    useEffect(() => {
        function handleMessage(e) {
            setValue(prevState => ({
                messages: [e.data]
            }))
        }
        navigator.serviceWorker.addEventListener('message', handleMessage);
        return () => navigator.serviceWorker.removeEventListener('message', handleMessage);
    }, []);

    return <MessagesContext.Provider value={value}>
        {children}
        <ToastContainer/>
    </MessagesContext.Provider>
}
