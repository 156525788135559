import {defaultAuthenticatedApiClient, defaultPublicApiClient} from "./ApiClient.ts";
import {tenantAuth} from "@/services/auth.service.ts";

export interface CreateAccountWithEmailAndPasswordParams {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    companyName?: string;
    industry?: string;
}

export interface CreateAccountWithGoogleParams {
    firstName: string;
    lastName: string;
    companyName: string;
    industry: string;
}

export interface TenantUpdateParams {
    displayName?: string | null;
    industry?: string | null;
}

export async function updateTenant(update: TenantUpdateParams): Promise<DoculyTenant> {

    return defaultAuthenticatedApiClient.httpPatch<DoculyTenant>('/my/tenant', update);
}

export async function resendVerificationEmail(): Promise<void> {

    await defaultAuthenticatedApiClient.httpPost<DoculyUser>('/accounts.send_email_verification');
}

export async function verifyAccount(uid: string): Promise<DoculyUser> {

    const user = defaultPublicApiClient.httpPost<DoculyUser>(`/accounts.verify/${uid}`);
    // the claims must be reloaded after this call
    await tenantAuth.currentUser!.getIdTokenResult(true);
    return user;
}

export async function createAccountWithEmailAndPassword(user: CreateAccountWithEmailAndPasswordParams): Promise<DoculyUser> {

    return defaultPublicApiClient.httpPost<DoculyUser>('/accounts.email_sign_up', user);
}

export async function createAccountWithGoogle(user?: CreateAccountWithGoogleParams): Promise<DoculyUser> {

    const newUser = await defaultAuthenticatedApiClient.httpPost<DoculyUser>('/accounts.fb_sign_up', user);
    // the claims must be reloaded after this call
    await tenantAuth.currentUser!.getIdTokenResult(true);
    return newUser;
}

export async function addWebPushSubscription(subscription: PushSubscription): Promise<void> {

    return defaultPublicApiClient.httpPost('/my/web_push_subscriptions', subscription);
}

export async function removeWebPushSubscription(subscription: PushSubscription): Promise<void> {

    return defaultPublicApiClient.httpPost('/my/web_push_subscriptions', subscription);
}

export interface DoculyAccount {
    user: DoculyUser;
    tenant: DoculyTenant;
    /**
     * All of the document types that have been imported by the tenant
     */
    documentTypes: string[];
    prompts: SavedPrompt[];
    pendingReviewCount: number;
}
export async function getAccount(): Promise<DoculyAccount> {

    return defaultAuthenticatedApiClient.httpGet<DoculyAccount>('/me');
}

export async function getSavedPrompts(): Promise<SavedPrompt> {

    return defaultAuthenticatedApiClient.httpGet<SavedPrompt>('/my/prompts');
}

export async function savePrompt(text: string): Promise<SavedPrompt> {

    return defaultAuthenticatedApiClient.httpPost<SavedPrompt>('/my/prompts', {
        text
    });
}

export async function deletePrompt(id: number): Promise<void> {

    return defaultAuthenticatedApiClient.httpDelete(`/my/prompts/${id}`);
}

export async function sendInvitations(invitations: InvitationParams[]): Promise<void> {

    return defaultAuthenticatedApiClient.httpPost(`/invitations.bulk_create`, {
        invitations
    });
}

export async function getInvitationById(invitationId: string): Promise<Invitation> {

    return defaultPublicApiClient.httpGet(`/invitations/${invitationId}`);
}

export async function acceptInvitation(invitationId: string, acceptance?: InvitationAcceptance): Promise<DoculyUser> {

    const user = await defaultAuthenticatedApiClient.httpPost(`/invitations.accept`, {
        invitationId,
        ...acceptance
    });
    await tenantAuth.currentUser?.getIdTokenResult(true);
    return user;
}
