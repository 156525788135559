import './DocumentTypeCellRenderer.scss';
import React, {useMemo} from "react";
import classnames from "classnames";
import {ICellRendererParams} from "@ag-grid-community/core";
import {capitalCase} from "change-case";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import {useNavigate} from "react-router-dom";
import * as process from "process";

const baseClassName = 'document-type-cell-renderer';

type DocumentInfo = {
    type: string;
    requiresReview: boolean;
    processing: boolean;
}

export function DocumentTypeCellRenderer({data}: ICellRendererParams) {

    const navigate = useNavigate();
    const {type, requiresReview, processing} = useMemo<DocumentInfo>(() =>  {
        return {
            type: capitalCase(data.docType ?? ''),
            requiresReview: data.requiresReview,
            processing: data.status === 'processing',
        }
    }, [data]);

    function handleReviewClick() {
        navigate(`/dashboard/extract/${data.id}`)
    }

    return <div className={classnames(baseClassName, {
        [`${baseClassName}--state-processing`]: processing
    })}>
        {requiresReview && (
            <DyTriggerIcon className={`${baseClassName}__warning-icon`} icon='warning' onClick={handleReviewClick}
                           tipDelay={[150, 0]} tip={'This document requires review.'}/>
        )}
        {processing ? 'Processing' : type}
    </div>
}

export default DocumentTypeCellRenderer;
