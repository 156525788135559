import './DocumentFieldValue.scss';
import React, {Fragment} from "react";
import classnames from "classnames";
import {capitalCase} from "change-case";
import {formatValue} from "@/utils/formatter.ts";
import {FieldConfig} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";


interface DocumentFieldValueProps {
    className?: string;
    field: DoculyField<string | Record<string, DoculyField>>;
    config?: FieldConfig;
}

const baseClassName = 'document-field-value';

export function DocumentFieldValue({field, config, className}: DocumentFieldValueProps) {

    const type = (field as any).type ?? field.datatype;

    if (type === 'object') {
        return <div className={classnames(baseClassName, className, `${baseClassName}--nested`)}>
            {Object.entries(field.value).map(([name, value]) => (<Fragment key={name}>
                <div className={`${baseClassName}__label`}>
                    {capitalCase(name)}
                </div>
                <div className={`${baseClassName}__value`}>
                    {formatValue(value, config?.format)}
                </div>
            </Fragment>))}
        </div>
    }

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--${config?.format}`]: config?.format
    })}>
        {formatValue(field.value, config?.format)}
    </div>

}

export default DocumentFieldValue;
