
// ******************************************************************************************************************
// PRIVATE UTILITY FUNCTIONS DO NOT ADD ANY NEW CODE BELOW THIS POINT (THIS IS THE PREVIOUS COMMENT)
// ******************************************************************************************************************

import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {apiConfig} from "../config";
import {tenantAuth} from "@/services/auth.service.ts";

type ApiHttpClientOptions = {
    enableAuth?: boolean;
    includeTenantId?: boolean;
}

export class ApiClient {

    private readonly http: AxiosInstance;
    constructor({enableAuth = true}: ApiHttpClientOptions = {}) {

        this.http = axios.create({
            baseURL: apiConfig.baseUrl
        })
        if(enableAuth) {
            const auth = tenantAuth;
            this.http.interceptors.request.use(async (config) => {
                if(auth.currentUser) {
                    config.headers.Authorization = `Bearer ${await auth.currentUser.getIdToken()}`
                }
                return config;
            })
        }
    }
    /**
     * A wrapper around axios.get that adds our custom error handling
     * @param url
     * @param config
     * @returns {Promise<any>}
     */
    async httpGet<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.get(url, config);
            return data;
        } catch (err) {
            throw normalizeError(err);
        }
    }
    /**
     * A wrapper around axios.post that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPost<T = any>(url: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.post(url, body, config);
            return data;
        } catch (err) {
            throw normalizeError(err);
        }
    }

    /**
     * A wrapper around axios.post that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<AxiosResponse<any>>}
     */
    public async rawHttpPost<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return this.http.post(url, body, config);
        } catch (err) {
            throw normalizeError(err);
        }
    }
    /**
     * A wrapper around axios.patch that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPatch<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.patch(url, body, config);
            return data;
        } catch (err) {
            throw normalizeError(err);
        }
    }
    /**
     * A wrapper around axios.put that adds our custom error handling
     * @param url
     * @param body
     * @param config
     * @returns {Promise<any>}
     */
    public async httpPut<T = any>(url: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const {data} = await this.http.put(url, body, config);
            return data;
        } catch (err) {
            throw normalizeError(err);
        }
    }
    /**
     * A wrapper around axios.delete that adds our custom error handling
     * @param url
     * @param config
     * @returns {Promise<any>}
     */
    public async httpDelete(url: string, config?: AxiosRequestConfig): Promise<void> {
        try {
            const {data} = await this.http.delete(url, config);
            return data;
        } catch (err) {
            throw normalizeError(err);
        }
    }
}

/**
 * ApiClient with not authorization or tenant information
 */
export const defaultPublicApiClient = new ApiClient();
/**
 * ApiClient with authorization but no tenant information
 */
export const defaultAuthenticatedApiClient = new ApiClient({enableAuth: true});

/**
 *
 * common error handle that should be used by all functions
 * @param err
 */
function normalizeError(err: any): any {
    // todo: add common error handling and event triggering for connectivity issues
    if (err?.response) {
        const { status, data } = err.response;
        return {
            status,
            code: data?.code,
            id: data?.id,
        };
    }

    if (err.isAxiosError) {
        return {
            status: -1,
            code: 'network_error',
        };
    }

    return err;
}
