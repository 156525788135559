import './ExtractFieldEditor.scss';
import React, {ChangeEvent, Fragment, useEffect, useState} from "react";
import classnames from "classnames";
import {useAppContext} from "@/contexts";
import Select, {ActionMeta, SingleValue} from "react-select";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import {capitalCase} from "change-case";
import AutoSizeTextArea from "react-textarea-autosize";
import {DyButton} from "@/components/core";
import {UserDocumentField} from "@/api";
import {formatDateRange} from "@/utils/formatter.ts";
import {DocumentFieldValue} from "@/components/DocumentFieldValue";

export interface ExtractFieldEditorProps extends React.HTMLAttributes<HTMLDivElement> {
    docType: string;
    field: UserDocumentField;

    onSave?(field: UserDocumentField): void | Promise<void>;

    /**
     * Only triggered when the preview would be for a newly created value.
     */
    onCancelNew?(): void;

    onDelete?(id: number): void;

    dyRef?: React.Ref<HTMLDivElement>;
}

const baseClassName = 'extract-field-editor';

export function ExtractFieldEditor({
                                       className,
                                       field,
                                       docType,
                                       dyRef,
                                       onDelete,
                                       onSave,
                                       onCancelNew,
                                       ...props
                                   }: ExtractFieldEditorProps) {

    // todo: clean up names and merge into a single state for mutable values
    const hasIssue = Boolean(field.issue)
    // this is logic isn't right we need to check for more than just string
    const isObject = (hasIssue && typeof field.value !== 'string') || (!hasIssue && field.type === 'object')
    const {fieldOptions} = useAppContext();
    const options = fieldOptions[docType];
    const [expanded, setExpanded] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(!field.value);
    const [value, setValue] = useState<any>(field.value);
    const [fieldType, setFieldType] = useState<string | undefined>(field.field);

    useEffect(() => {
        if(editMode) {
            setValue(field.value);
            setFieldType(field.field);
        }
    }, [field, editMode]);

    function toggleExpanded() {
        setExpanded(expanded => !expanded)
    }

    function handleDeleteClick() {
        onDelete?.(field.id)
    }

    function handleEditClick() {
        setEditMode(true);
        setValue(() => {
            return field.value;
        });
    }

    function handleCancelClick() {
        if (field.id) {
            setEditMode(false);
        } else {
            onCancelNew?.();
        }
    }

    async function handleSaveClick() {
        await onSave?.({
            ...field,
            value,
            field: fieldType!
        });
        setEditMode(!field.id);
    }

    function handleValueChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        setValue(e.target.value);
    }

    function handleNestedChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const {name, value} = e.target;

        setValue(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    function handleFieldTypeChange(value: SingleValue<DisplayValueKey>, actionMeta: ActionMeta<number>): void {
        setFieldType(value?.key);
    }

    return <div className={classnames(`${baseClassName}`, className, `${baseClassName}--`, {
        [`${baseClassName}--expanded`]: expanded
    })} key={field.id} ref={dyRef} {...props}>
        <header className={classnames(`${baseClassName}__header`, {
            [`${baseClassName}__header--editing`]: editMode && !isObject
        })}>
            {editMode && !isObject ? <>
                <Select className={`${baseClassName}__name-input`}
                        isMulti={false}
                        onChange={handleFieldTypeChange as any}
                        value={options.filter(o => o.key === fieldType)}
                        getOptionLabel={option => option.display}
                        options={options}/>
            </> : <>
                <h1 className={`${baseClassName}__name`}>
                    {capitalCase(field.field)}
                </h1>
                {!editMode && <DyTriggerIcon icon={expanded ? 'contract' : 'expand'} onClick={toggleExpanded}/>}
            </>}
        </header>
        {expanded && (<>
            <main className={classnames(`${baseClassName}__value`, {
                [`${baseClassName}__value--editing`]: editMode
            })}>
                {hasIssue && (<div className={`${baseClassName}__issue`}>
                    {field.issue}
                </div>)}
                {editMode ?
                    isObject ?
                        Object.entries(value).map(([name, value]) => (<Fragment key={name}>
                            <div className={`${baseClassName}__value-label`}>
                                {capitalCase(name)}
                            </div>
                            <div className={`${baseClassName}__value-input`}>
                                <AutoSizeTextArea className={`${baseClassName}__input`}
                                                  name={name}
                                                  placeholder={'Enter a value'}
                                                  onChange={handleNestedChange}
                                                  value={formatDateRange(value)}/>
                            </div>
                        </Fragment>)) :
                        <div className={`${baseClassName}__value-input`}>
                            <AutoSizeTextArea className={`${baseClassName}__input`}
                                              placeholder={'Enter a value'}
                                              onChange={handleValueChange} value={value as string ?? ''}/>

                        </div>
                    : <DocumentFieldValue field={field as any}/>
                }
            </main>

            <footer className={`${baseClassName}__actions`}>
                {editMode ? <>
                    <DyButton dyType={'light'} onClick={handleCancelClick}>Cancel</DyButton>
                    <DyButton dyType={'light'} disabled={!value || !fieldType} onClick={handleSaveClick}>Save</DyButton>
                </> : <>
                    <DyTriggerIcon className={`${baseClassName}__delete`}
                                   icon={'delete'}
                                   tipPlacement={'bottom-start'}
                                   tipDelay={[250, 0]}
                                   tip={<>
                                       <p>Remove this extracted field.</p>
                                   </>}
                                   onClick={handleDeleteClick}/>
                    <DyButton dyType={'light'} onClick={handleEditClick}>Edit Value</DyButton>
                    {Boolean(field.issue) &&
                        <DyButton dyType={'positive'} onClick={handleSaveClick}>Ignore Issue</DyButton>}
                </>
                }
            </footer>
        </>)}
    </div>
}

export default ExtractFieldEditor;
