import './DocumentListDetail.scss';
import React, {useMemo} from "react";
import classnames from "classnames";
import {DocumentFieldValue} from "@/components/DocumentFieldValue";
import {AggregatedField} from "@/components/DocumentFieldValue/DocumentFieldValue.types";
import DocumentFieldValueList from "@/components/DocumentFieldValue/DocumentFieldValueList";
import DocumentFieldValueTable from "@/components/DocumentFieldValue/DocumentFieldValueTable";
import {groupAndPrepareFields} from "@/services/field.service";
import {snakeCase} from "lodash";

export interface DocumentListDetailProps {
    data: DoculyDoc;
}

const baseClassName = 'document-list-detail';

export function DocumentListDetail({data: doc}: DocumentListDetailProps) {

    // todo: this needs a shit ton of clean up
    const {fieldMap, fieldGroups} = useMemo<{ fieldMap: Record<string, AggregatedField>, fieldGroups: any[] }>(() => {

        const fieldMap = groupAndPrepareFields(doc);
        const docConfig = window.docTypeConfigs.get(doc.docType);
        let fieldGroups;

        if (docConfig?.layout.length) {
            // use explicit layout
            fieldGroups = docConfig.layout;
        } else if (docConfig?.fieldOrder.length) {
            // produce layout from field order (todo: this might go away)
            // todo: fix keys so they're always snake-case in the db and ui, not sure why it was changed
            fieldGroups = [{fields: docConfig.fieldOrder.map(key => snakeCase(key))}]
        } else {
            // produce a default alphabetical listing of values
            fieldGroups = [{fields: [Object.keys(fieldMap).sort()]}]
        }

        // remove groups with no matching fields in the current document
        fieldGroups = fieldGroups.filter(({fields}) => fields.some(key => fieldMap[snakeCase(key)]));

        return {
            fieldGroups,
            fieldMap
        };
    }, [doc]);

    return <div className={classnames(baseClassName)}>
        {fieldGroups.map(group => (
            <div className={`${baseClassName}__field-group`}>
                {group.label && (
                    <div className={`${baseClassName}__field-group-label`}>
                        {group.label}
                    </div>
                )}
                {group.fields.map((key, index) => {

                    const field = fieldMap[snakeCase(key)];

                    if (!field) return <></>

                    return (
                        <div className={`${baseClassName}__field`} key={index}>
                            <h1 className={`${baseClassName}__label`}>{field.displayName}</h1>
                            {field.config?.table === true
                                ? <DocumentFieldValueTable className={`${baseClassName}__value`}
                                                           fields={field.values as any}
                                                           config={field.config}/>
                                : field.values.length > 1
                                    ?
                                    <DocumentFieldValueList className={`${baseClassName}__value`}
                                                            config={field.config}
                                                            fields={field.values as any}/>
                                    : <DocumentFieldValue className={`${baseClassName}__value`}
                                                          config={field.config}
                                                          field={field.values[0] as any}/>
                            }
                        </div>
                    );
                })}
            </div>
        ))}
    </div>
}

export default DocumentListDetail;
