import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {Auth, Unsubscribe, User} from "firebase/auth";
import {createAccountWithGoogle, getAccount, getCurrentDocumentTypes} from "@/api";
import {AppAnalytics} from "@/contexts/analytics.context.tsx";
import {getDocTypeConfigs, getFieldOptions} from "@/api/refdata.api.ts";
import {subscribeToNotifications} from "@/services/notification.service.ts";
import {useServerEvents} from "@/contexts/useServerEvents.ts";
import {tenantAuth} from "@/services/auth.service.ts";

export interface AppContextValue {
    user?: DoculyUser;
    loadingAccount: boolean;
    tenant?: DoculyTenant;
    initialized: boolean;
    allDocumentTypes?: string[];
    searchResults?: DoculyDoc[];
    savedPrompts?: SavedPrompt[];

    setSearchResults(searchResults: DoculyDoc[] | undefined): void;

    refreshAllDocumentTypes(): Promise<void>;

    addSavedPrompt(question: SavedPrompt): void;

    removeSavedPrompt(question: SavedPrompt): void;

    fieldOptions: Record<string, DisplayValueKey[]>;
    docTypeConfigs: DocTypeConfig[];
    pendingReviewCount: number;
    fbUser?: User | null;

    refresh(): Promise<void>
}

export const AppContext = React.createContext<AppContextValue>(null as any);

interface AppProviderProps {
    children: ReactNode | ReactNode[];
}

export function AppProvider({children}: AppProviderProps) {

    const {events} = useServerEvents();
    const [value, setValue] = useState<AppContextValue>({
        initialized: false,
        loadingAccount: false,
        setSearchResults(searchResults: DoculyDoc[] | undefined) {
            setValue(value => ({
                ...value,
                searchResults
            }))
        },
        async refreshAllDocumentTypes() {
            const allDocumentTypes = await getCurrentDocumentTypes();
            setValue(value => ({
                ...value,
                allDocumentTypes
            }))
        },
        addSavedPrompt(question: SavedPrompt) {
            setValue(value => ({
                ...value,
                savedPrompts: [...value.savedPrompts!, question]
            }))
        },
        removeSavedPrompt(question: SavedPrompt): void {
            setValue(value => ({
                ...value,
                savedPrompts: value.savedPrompts?.filter(q => q !== question)
            }));
        },
        fieldOptions: {},
        docTypeConfigs: [],
        pendingReviewCount: 0,
        async refresh() {
            if (tenantAuth.currentUser) {
                setValue(prevState => ({
                   ...prevState,
                   loadingAccount: true,
                }))
                try {
                    await loadContextValues();
                } finally {
                    setValue(prevState => ({
                        ...prevState,
                        loadingAccount: false,
                    }))
                }
            }
        }
    });

    const loadContextValues = useCallback(async () => {

        const fbUser = tenantAuth.currentUser;

        if (!fbUser?.emailVerified) return;

        const {user, tenant, documentTypes, prompts, pendingReviewCount} = await getAccount();

        setValue(value => ({
            ...value,
            user,
            tenant,
            allDocumentTypes: documentTypes,
            savedPrompts: prompts,
            initialized: true,
            pendingReviewCount
        }));
    }, []);

    useEffect(() => {
        if (!events.length) return;

        console.log(`Received event [${events[0].type}]`)


        ;(async () => {
            const auth = tenantAuth;

            switch (events[0].type) {
                case 'account.verified':
                    await auth.currentUser?.reload();
                    setValue(prevState => ({
                        ...prevState,
                        fbUser: auth.currentUser
                    }))
                    await loadContextValues();
                    break;
                case 'tenant.approved':
                    await loadContextValues();
                    break;
            }
        })();
    }, [loadContextValues, events])

    useEffect(() => {

        (async () => {
            const [fieldOptions, docTypeConfigs] = await Promise.all([
                getFieldOptions(),
                getDocTypeConfigs(),
            ]);
            setValue(value => ({
                ...value,
                fieldOptions,
                docTypeConfigs,
            }))

            window.docTypeConfigs = new Map(docTypeConfigs.map(c => [c.code, c]));
        })();

        let webPushSubscription: Promise<PushSubscription | undefined> | undefined;
        const auth: Auth = tenantAuth;

        const unsubscribeAuthStateChanged: Unsubscribe = auth.onAuthStateChanged(async (fbUser: User | null) => {
            if (fbUser) {
                try {
                    const {claims} = await fbUser.getIdTokenResult();
                    setValue(value => ({
                        ...value,
                        loadingAccount: true,
                        fbUser
                    }))

                    if (!fbUser.emailVerified) {
                        setValue(value => ({
                            ...value,
                            initialized: true,
                        }));
                        return;
                    }

                    if (!claims.tenantId && !claims.userId) {
                        await createAccountWithGoogle();
                        await fbUser.getIdTokenResult(true);
                    }

                    await loadContextValues();
                    webPushSubscription = subscribeToNotifications();

                } catch (err: any) {
                    if (err.status === 401) {
                        auth.signOut();
                    }
                } finally {
                    setValue(value => ({
                        ...value,
                        initialized: true,
                        loadingAccount: false
                    }))
                }
            } else {
                // this is ugly needs a cleaner solution, but it allows us to handle notifications without blocking
                webPushSubscription?.then(subscription => subscription?.unsubscribe());
                setValue(value => ({
                    ...value,
                    user: undefined,
                    tenant: undefined,
                    initialized: true,
                    pendingReviewCount: 0,
                    fbUser: null,
                }));
            }
        });

        return () => {
            unsubscribeAuthStateChanged();
        }
    }, []);

    return <AppContext.Provider value={value}>
        <AppAnalytics>
            {children}
        </AppAnalytics>
    </AppContext.Provider>
}

