import './DocumentFieldValueList.scss';
import React from "react";
import classnames from "classnames";
import DocumentFieldValue from "@/components/DocumentFieldValue/DocumentFieldValue.tsx";
import {FieldConfig} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";

export interface DocumentFieldValueListProps {
    className?: string;
    fields: DoculyField[];
    config?: FieldConfig;
}

const baseClassName = 'document-field-value-list';

export function DocumentFieldValueList({className, fields}: DocumentFieldValueListProps) {

    const isSingle = fields[0]?.datatype !== 'object';

    return <div className={classnames(baseClassName, className)}>
        {fields?.map?.(field => <DocumentFieldValue className={classnames(`${baseClassName}__value`, {
            [`${baseClassName}__value--single`]: isSingle
        })} field={field}/>)}
    </div>
}

export default DocumentFieldValueList;
