import './DashboardHeader.scss';
import React from "react";
import classnames from "classnames";
import {useAppContext} from "@/contexts";
import {AskMeAnything} from "@/components/AskMeAnything";
import {Convo} from "@/components/InteractiveChat";
import {useLocation, useNavigate} from "react-router-dom";

export interface DashboardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'dashboard-header';

export function DashboardHeader({className, ...props}: DashboardHeaderProps) {

    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {tenant} = useAppContext();

    function handleStartConvo(convo: Convo) {
        navigate('/dashboard/chat', {
            state: convo,
            // if we're appending to the current chat we want to use replace so that exit will take the user back
            // to where they started and not go back through a history of chats
            replace: pathname === '/dashboard/chat'
        });
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <h1 className={`${baseClassName}__tenant-name`}>{tenant?.displayName}</h1>
        <div className={`${baseClassName}__ask-me-anything`}>

            <AskMeAnything className={`${baseClassName}__ama`} onStartConvo={handleStartConvo}/>
        </div>
    </div>
}

export default DashboardHeader;
