import './DocumentImporter.scss';
import React, {ChangeEvent} from "react";
import classnames from "classnames";
import {DyDialog} from "@/components/core/DyDialog";
import {DyTextArea} from "@/components/core/DyTextArea";
import {DyFileInput} from "@/components/core/DyFileInput";
import {DyButton} from "@/components/core";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import FileUploadList from "@/components/importWizard/FileUploadMonitor/FileUploadList.tsx";
import {useFileUpload} from "@/components/importWizard/FileUploadMonitor/FileUploadContext.ts";
import {toast} from "react-toastify";
import {subscribeToNotifications} from "@/services";

export interface DocumentImporterProps extends React.HTMLAttributes<HTMLDivElement> {
    onComplete?(importedIds: string[]): void;

    onCancel?(): void;

    onClose?(): void;
}

const acceptableTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
const ACCEPTABLE_TYPES = acceptableTypes.join(',');

const baseClassName = 'document-importer';

export function DocumentImporter({className, onComplete, onCancel, onClose, ...props}: DocumentImporterProps) {

    const {complete, uploads, addUploads} = useFileUpload()!;

    function handleClose() {
        onClose?.();
        onCancel?.();
    }

    async function handleComplete() {
        toast(<>We are processing your files.  We'll notify as soon as they're ready.</>)

        onClose?.();
    }

    async function handleFilesChange({target}: ChangeEvent<HTMLInputElement>) {

        const files = [...target.files ?? []].filter(file => acceptableTypes.includes(file.type));
        addUploads(files);
    }

    async function handleExtractClick() {
        onComplete?.(uploads.filter(v => Boolean(v.guid)).map(v => v.guid!));
    }

    return <DyDialog className={classnames(baseClassName, className)} {...props}>
        <DyDialog.LogoHeader onClose={handleClose}/>
        <DyDialog.Message>
            Upload one or more documents. You can provide any additional instructions.
        </DyDialog.Message>
        <DyDialog.Content className={classnames(`${baseClassName}__content`, {
            [`${baseClassName}__content--has-uploads`]: uploads.length
        })}>
            <DyTextArea className={`${baseClassName}__prompt`} placeholder={'Provide additional details...'}/>
            <DyFileInput onChange={handleFilesChange} multiple={true} accept={ACCEPTABLE_TYPES}/>
            <FileUploadList className={`${baseClassName}__uploads`}/>
        </DyDialog.Content>
        {Boolean(uploads.length) && (
            <DyDialog.Actions className={`${baseClassName}__actions`}>
                <DyButton className={`${baseClassName}__action--done`} disabled={!complete}
                          onClick={handleComplete}>
                    Done
                </DyButton>
            </DyDialog.Actions>
        )}
    </DyDialog>
}

export default DocumentImporter;
