import './ExtractReviewer.scss';
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {useParams} from "react-router-dom";
import {
    addField,
    getReviewDocument,
    removeField,
    SourcePosition,
    updateField,
    UserDocument,
    UserDocumentField
} from "@/api";
import ExtractFieldEditor from "@/components/ExtractReviewer/ExtractFieldEditor.tsx";
import PdfReviewer from "@/components/ExtractReviewer/PdfReviewer.tsx";
import {DySwitch} from "@/components/core/DySwitch";
import {DyButton} from "@/components/core";
import {dyConfirm} from "@/components/core/DyModal";

export interface ExtractReviewerProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'extract-reviewer';

export function ExtractReviewer({className, ...props}: ExtractReviewerProps) {
    const {id} = useParams<{ id: string }>();
    const docId = Number(id);
    const [document, setDocument] = useState<UserDocument>();
    const [loading, setLoading] = useState<boolean>(true);
    const [showAllFields, setShowAllFields] = useState<boolean>(false);
    const [sourcePositions, setSourcePositions] = useState<SourcePosition[]>([]);
    const [config, setConfig] = useState<DocTypeConfig | undefined>();
    const [newFields, setNewFields] = useState<UserDocumentField[]>([]);
    const lastFieldRef = useRef<HTMLDivElement>(null);

    const fields = useMemo<UserDocumentField[]>(() => {
        if (!document || !config) return [];
        return config.fieldOrder.map((field) => document.fields.filter(f => {
            return f.field === field && f.value && (showAllFields || f.issue)
        })).flat()

    }, [showAllFields, document, config])
    useEffect(() => {
        (async () => {
            try {
                setDocument(undefined);
                const document = await getReviewDocument(docId);
                setConfig(window.docTypeConfigs.get(document?.type))
                setDocument(document);
                setShowAllFields(!document?.requiresReview);
            } catch (err) {
                // do nothing
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    function handleFieldClick(field: UserDocumentField) {
        setSourcePositions(field.srcPositions ?? []);
    }

    function handleShowAllChange(e: ChangeEvent<HTMLInputElement>): void {
        setShowAllFields(e.target.checked)
    }

    function handleNewFieldDelete(index: number) {
        setNewFields(prevState => prevState.filter((_, i) => i !== index));
    }

    async function handleFieldDelete(fieldId: number): Promise<void> {

        if (!await dyConfirm({
            title: 'Remove Field',
            content: <>
                <p>Are you sure you want to remove this field?</p>
                <p>This cannot be undone.</p>
            </>
        })) return;

        try {
            setDocument(document => {
                const fields = document!.fields.filter(f => f.id !== fieldId);
                const requiresReview = fields.some(f => Boolean(f.issue))
                return {
                    ...document!,
                    fields,
                    requiresReview
                }
            });
            await removeField(docId, fieldId);
        } catch (err) {
            //
        }
    }

    async function handleFieldSave(field: UserDocumentField): Promise<void> {

        try {
            await updateField(docId, field.id, field.field, field.value);
            setDocument(document => ({
                ...document!,
                fields: document!.fields.map(f => f.id !== field.id ? f : {
                    ...f,
                    field: field.field,
                    value: field.value,
                    issue: undefined,
                })
            }))
        } catch (err) {
            // todo: handle errors
        }
    }

    async function handleNewFieldSave(field: UserDocumentField, index: number): Promise<void> {

        try {
            const newField = await addField(docId, field.field, field.value);
            setDocument(document => ({
                ...document!,
                fields: [...document!.fields, newField]
            }));
            setNewFields(prevState => prevState.filter((_, i) => i !== index));
        } catch (err) {
            // todo: handle errors
        }
    }

    function handleAddField() {

        setNewFields(prevState => {
            setTimeout(() => {
                lastFieldRef.current?.scrollIntoView({block: 'end', behavior: 'smooth'});
            }, 0);
            return [...prevState, {
                srcPositions: []
            } as any]
        });
    }

    if (loading) return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__loader`}>
            Loading document for review...
        </div>
    </div>

    if (!document) return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__error`}>
            Unable to find a review for id {id}.
        </div>
    </div>

    return <div className={classnames(baseClassName, className)} {...props}>
        <header>
            <h1>Reviewing {document.filename}</h1>
        </header>
        <main className={`${baseClassName}__content`}>
            <div className={`${baseClassName}__left`}>
                <div className={`${baseClassName}__fields`}>
                    {fields.map(field => (
                        <ExtractFieldEditor docType={document.type}
                            // dyRef={index === extract.fields.length - 1 ? lastFieldRef : undefined}
                                            onClick={() => handleFieldClick(field)}
                                            onDelete={handleFieldDelete}
                                            onSave={handleFieldSave}
                                            field={field}/>
                    ))}
                    {newFields.map((field, index) => (
                        <ExtractFieldEditor docType={document.type}
                                            dyRef={index === newFields.length - 1 ? lastFieldRef : undefined}
                                            onCancelNew={() => handleNewFieldDelete(index)}
                                            onSave={(field) => handleNewFieldSave(field, index)}
                                            field={field}/>
                    ))}
                </div>
                <div className={`${baseClassName}__actions`}>

                    <DySwitch checked={showAllFields} onChange={handleShowAllChange}>
                        Show All Fields
                    </DySwitch>
                    <DyButton onClick={handleAddField}>Add New Field</DyButton>
                </div>
            </div>
            <PdfReviewer className={`${baseClassName}__preview`}
                         file={document.srcUrl} highlights={sourcePositions}/>
        </main>
    </div>
}

export default ExtractReviewer;
