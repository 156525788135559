import {defaultAuthenticatedApiClient} from "@/api/ApiClient.ts";
import {capitalCase} from "change-case";

export async function extractDocumentFields(guid: string, prompt?: string): Promise<DoculyExtract> {

    const extract = await defaultAuthenticatedApiClient.httpPost('/documents.extract_fields', {
        guid,
        prompt,
    });

    return {
        ...extract,
        fields: extract.fields.map(({id, score, datatype, rawValue, name, value}) => ({
            id,
            score,
            displayName: capitalCase(name),
            datatype,
            rawValue,
            value
        })).sort((a, b) => a.displayName.localeCompare(b.displayName)),
    }
}
