// this type's sole purpose is to make it clear what value will be used as the key
import React, {useContext} from "react";

export interface FileUpload {
    file: File;
    progress: number;
    uploadUrl?: string;
    id?: number;
    guid?: string;
}

export interface FileUploadContextValue {
    uploads: FileUpload[];
    complete: boolean;

    addUploads(files: File[]): void;

    removeUpload(file: File): void;
}

export const FileUploadContext = React.createContext<FileUploadContextValue | null>(null);
FileUploadContext.displayName = 'FileUploadContext';

export const useFileUpload = () => useContext(FileUploadContext);



