import axios, {AxiosProgressEvent} from "axios";
import saveAs from "file-saver";
import Excel from "exceljs";
import {map} from "lodash";
import {capitalCase} from "change-case";
import {getDownloadUrl} from "@/api";
import {formatDateRange} from "@/utils/formatter.ts";

function noop(): any {

}
export interface UploadFileParams {
    file: File,
    url: string;
    onUploadProgress?(progressEvent: AxiosProgressEvent): void;
}

export async function uploadFile({url, file, onUploadProgress = noop}: UploadFileParams): Promise<void> {

    await axios.put(url, file, {
        onUploadProgress,
        headers: {
            'Content-Type': file.type
        }
    });
}


export async function exportFieldsAsExcel(doc: DoculyDoc, fieldNames?: string[]): Promise<void> {

    const workbook = generateWorkbook(doc, fieldNames);

    saveAs(new Blob([await workbook.xlsx.writeBuffer()], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }), 'extract.xlsx')
}

export async function exportFieldsAsCsv(doc: DoculyDoc, fieldNames?: string[]): Promise<void> {

    const workbook = generateWorkbook(doc, fieldNames);

    saveAs(new Blob([await workbook.csv.writeBuffer()], {
        type: "text/csv"
    }), 'extract.csv')
}


function generateWorkbook(doc: DoculyDoc, fieldNames?: string[]): Excel.Workbook {

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Doculy Extract')
    const missingColumns = fieldNames

    worksheet.columns = [
        {header: 'File'},
        {header: 'Document Type'},
        ...doc.fields.map(field => ({
            header: capitalCase(field.displayName.replace(/#.*$/, '')),
            alignment: {
                wrapText: true
            }
        }))
    ];

    const row = [
        doc.filename,
        capitalCase(doc.docType),
        ...doc.fields.map(({value}) => typeof value === 'string' ?
            formatDateRange(value) :
            map(value as Record<string, DoculyField>, toDisplayKvp)
                .filter(Boolean)
                .join('\r\n')),
    ];

    worksheet.addRows([row]);
    autoExpandColumnWidths(worksheet, 100);

    return workbook;
}

function autoExpandColumnWidths(worksheet, maxWidth: number = Infinity) {
    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        column.width = Math.min(maxWidth, Math.max(...lengths.filter(v => typeof v === 'number')));
        column.alignment = {
            ...column.alignment,
            wrapText: true,
            vertical: 'top'
        };
    });
}

export async function downloadDocument(doc: DoculyDoc): Promise<void> {

    const link = document.createElement('a');
    document.body.appendChild(link); // Firefox requires the link to be in the body
    link.download = doc.filename;
    link.href = await getDownloadUrl(doc.id);
    link.click();
    document.body.removeChild(link); // remove the link when done
}

function toDisplayKvp(value: any, key: string): string | null {
    if(value === null || value === undefined) return null;

    return `${capitalCase(key)}: ${formatDateRange(value)}`
}
