import './DyTextArea.scss';
import React from "react";
import classnames from "classnames";

export interface DyTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {

}

const baseClassName = 'dy-text-area';

export function DyTextArea({className, ...props}: DyTextAreaProps) {
    return <textarea className={classnames(baseClassName, className)} {...props}>

    </textarea>
}

export default DyTextArea;
