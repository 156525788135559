import React, {createContext, useContext, useEffect, useMemo, useRef} from 'react';
import ModalContainer, {ContainerRef} from "react-modal-promise";
import {useLocation} from "react-router-dom";
import {noop} from 'lodash'

export type DyModalContextValue = {
    reject(id: string): void;
    rejectAll(): void;
    resolve(id: string): void;
    resolveAll(): void;
    getContainer(): ContainerRef | null;
}
export const DyModalContext = createContext<DyModalContextValue>({
    getContainer: noop as any,
    reject: noop,
    rejectAll: noop,
    resolve: noop,
    resolveAll: noop,
});

export const useDyModalContainer = () => useContext(DyModalContext);

export function DyModalContainer({children}) {

    const containerRef = useRef<ContainerRef>(null);
    const location = useLocation();
    const state = useMemo<DyModalContextValue>(() => ({
        reject(id) {
            containerRef.current?.reject(id);
        },
        rejectAll() {
            containerRef.current?.rejectAll();
        },
        resolve(id) {
            containerRef.current?.resolve(id);
        },
        resolveAll() {
            containerRef.current?.resolveAll();
        },
        getContainer(): ContainerRef | null {
            return containerRef.current;
        }
    }), [])

    useEffect(() => {
        containerRef.current?.rejectAll();
    }, [location])

    useEffect(() => {
        // @ts-ignore
        window.dyModals = {
            reject(id) {
                containerRef.current?.reject(id)
            },
            resolve(id) {
                containerRef.current?.resolve(id)
            },
            rejectAll() {
                containerRef.current?.rejectAll()
            },
            resolveAll() {
                containerRef.current?.resolveAll()
            },
            getContainer() {
                return containerRef.current;
            }
        }
    }, [containerRef.current]);

    return <DyModalContext.Provider value={state}>
        {children}
        <ModalContainer exitTimeout={0} enterTimeout={0} ref={containerRef}/>
    </DyModalContext.Provider>
}

export default React.memo(DyModalContainer);
