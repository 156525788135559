import './PendingReviewList.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";

export interface PendingReviewListProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'pending-review-list';

export function PendingReviewList({className, ...props}: PendingReviewListProps) {
    return <div className={classnames(baseClassName, className)} {...props}>
        <span>Pending Reviews</span>
    </div>
}

export default PendingReviewList;
