import './FileUploadMonitor.scss';
import React, {memo} from "react";
import classnames from "classnames";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";
import prettyBytes from 'pretty-bytes';
import {FileUpload} from "@/components/importWizard/FileUploadMonitor/FileUploadContext.tsx";
import {HTMLMotionProps, motion} from 'framer-motion';

export interface FileUploadMonitorProps extends HTMLMotionProps<'div'> {
    upload: FileUpload;

    onDelete?(upload: FileUpload): void;
}

const baseClassName = 'file-upload-monitor';

export const FileUploadMonitor = memo(({
                                           className,
                                           upload,
                                           onDelete,
                                           ...props
                                       }: FileUploadMonitorProps) => {

    const {file, progress} = upload;
    const pctComplete = `${Math.floor(progress * 100)}%`;

    function handleRemove(): void {
        onDelete?.(upload);
    }

    return <motion.div className={classnames(baseClassName, className)} {...props} >
        <div className={`${baseClassName}__filename`}>{file.name}</div>
        <div className={`${baseClassName}__size`}>{prettyBytes(file.size)}</div>
        <DyTriggerIcon className={`${baseClassName}__delete`} icon={'delete'} onClick={handleRemove}/>
        <div className={`${baseClassName}__progress`}>
            <div className={`${baseClassName}__progress-bar`}>
                <div className={`${baseClassName}__progress-bar-fill`} style={{width: pctComplete}}/>
            </div>
            <div className={`${baseClassName}__progress-pct`}>{pctComplete}</div>
        </div>
    </motion.div>
});

export default FileUploadMonitor;
