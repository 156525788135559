import './DyMenu.scss';
import React, {ReactNode, useMemo, useRef} from "react";
import classnames from "classnames";
import Tippy, {TippyProps} from "@tippyjs/react";
import {Instance} from "tippy.js";

export type DyMenuItemConfig = {
    label: ReactNode;
    action(): Promise<void> | void;
    icon?: string;
    include?: boolean;
}

export interface DyMenuProps extends TippyProps {
    items: DyMenuItemConfig[];
}

const baseClassName = 'dy-menu';

export function DyMenu({className, children, items}: DyMenuProps) {

    const menuRef = useRef<Instance | null>(null);
    const content = useMemo<ReactNode>(() => {

        function handleClick({action}: DyMenuItemConfig): void {
            menuRef.current?.hide();
            action();
        }

        return <div className={baseClassName}>
            <menu>
                {items.filter(o => o.include !== false).map((option, i) => (
                    <li key={i} onClick={() => handleClick(option)}>
                        {option.label}
                    </li>
                ))}
            </menu>
        </div>
    }, [items]);

    function handleMenuShown(instance: Instance) {
        menuRef.current = instance;
    }

    return <Tippy placement={'right-end'}
                  trigger={'click'}
                  interactive={true}
                  onShown={handleMenuShown}
                  className={classnames(baseClassName, className)}
                  arrow={false}
                  appendTo={document.body}
                  content={content}>
        {children}
    </Tippy>
}

export default DyMenu;
