
import './UserMessage.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {UserPrompt} from "@/components/InteractiveChat/InteractiveChat.types.ts";
import {useAppContext} from "@/contexts";

export interface UserMessageProps extends React.HTMLAttributes<HTMLDivElement> {
    prompt: UserPrompt;
    dyRef?: React.Ref<HTMLDivElement>;
}

const baseClassName = 'user-message';

export function UserMessage({className, prompt, dyRef, ...props}: UserMessageProps) {
    const {user} = useAppContext();
    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__message`} ref={dyRef}>
            <div className={`${baseClassName}__from`}>
                {user?.firstName} {user?.lastName}
            </div>
            <div className={`${baseClassName}__text`}>
                {prompt.prompt}
            </div>
        </div>
    </div>
}

export default UserMessage;
