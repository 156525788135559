import './DyButton.scss';
import React, {ReactNode} from "react";
import classnames from "classnames";

export interface DyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: ReactNode;
    dyType?: 'default' | 'primary' | 'secondary' | 'positive' | 'dark' | 'navigation' | 'danger' | string;
    busy?: boolean;
}

const baseClassName = 'dy-button';

export function DyButton({className, children, label = children, dyType = 'default', busy, ...props}: DyButtonProps) {
    return <button className={classnames(baseClassName, className, `${baseClassName}--type-${dyType}`, {
        [`${baseClassName}--disabled`]: props.disabled,
        [`${baseClassName}--busy`]: busy
    })} {...props}>
        <span className={`${baseClassName}__label`}>{label}</span>
        {busy && <div className={`${baseClassName}__busy-mask`}/> }
    </button>
}

export default DyButton;
