import React, {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import {Unsubscribe, User} from "firebase/auth";
import {and, collection, getFirestore, onSnapshot, or, query, where} from "firebase/firestore";
import {tenantAuth} from "@/services/auth.service.ts";
import {firebaseConfig} from "@/config";

export type ServerEvent = {
    body?: any;
    type?: string;
}

export interface ServerEventsContextValue {
    events: ServerEvent[];
    resubscribe(): Promise<void>;
}

export const ServerEventsContext = React.createContext<ServerEventsContextValue>({} as any);

export interface ServerEventsProviderProps {
    children: ReactNode | ReactNode[];
}

export function ServerEventsProvider({children}: ServerEventsProviderProps) {

    const unsubscribeEvents = useRef<Unsubscribe | undefined>();
    const [value, setValue] = useState<ServerEventsContextValue>({
        events: [],
        async resubscribe() {
            return resubscribe();
        }
    });
    const resubscribe = useCallback(async (): Promise<void> => {

        const db = firebaseConfig.databaseName ? getFirestore(firebaseConfig.databaseName) : getFirestore();
        unsubscribeEvents.current?.();
        const fbUser = tenantAuth.currentUser;

        if(!fbUser) return;

        const {claims} = await fbUser.getIdTokenResult();

        const clauses = [and(where('target', '==', claims.email), where('targetType', '==', 'email'))];

        if(claims.userId) {
            clauses.push(and(where('target', '==', claims.userId), where('targetType', '==', 'user')));
        }

        if(claims.tenantId) {
            clauses.push(and(where('target', '==', claims.tenantId), where('targetType', '==', 'tenant')))
        }
        unsubscribeEvents.current = onSnapshot(query(collection(db, 'events'), and(
            where('timestamp', '>', new Date()),
            or(...clauses)
        )), async (snapshot) => {
            const events = snapshot.docs.map(v => v.data());

            setValue(prevState => ({
                ...prevState,
                events
            }));
        });
    }, []);

    useEffect(() => {

        const unsubscribeLogin: Unsubscribe = tenantAuth.onIdTokenChanged(async (fbUser: User | null) => {

            if (!fbUser) {
                return unsubscribeEvents.current?.();
            }

            await resubscribe();

        });

        return () => {
            unsubscribeEvents.current?.();
            unsubscribeLogin()
        }
    }, []);


    return <ServerEventsContext.Provider value={value}>
        {children}
    </ServerEventsContext.Provider>
}
