import './DyTriggerIcon.scss';
import React, {ReactNode, useMemo, useRef} from "react";
import classnames from "classnames";
import Tippy from "@tippyjs/react";
import {Placement} from 'tippy.js';

export interface DyTriggerIconProps extends React.HTMLAttributes<HTMLButtonElement> {
    icon: 'refresh' | 'logout' | 'close' | string;
    theme?: 'light' | 'dark' | 'blue';
    tip?: ReactNode;
    tipPlacement?: Placement;
    tipDelay?: number | [number | null, number | null];
}

const baseClassName = 'dy-trigger-icon';

export function DyTriggerIcon({
                                  className,
                                  icon,
                                  theme,
                                  style,
                                  tip,
                                  tipPlacement = 'bottom',
                                  tipDelay,
                                  ...props
                              }: DyTriggerIconProps) {

    const buttonRef = useRef<HTMLButtonElement>(null);
    const stylePlus = useMemo<React.CSSProperties>(() => ({
        [`--dy-trigger-icon`]: icon,
        ...style
    }), [icon, style]);

    return <>
        <button ref={buttonRef} className={classnames(baseClassName, className,
            `${baseClassName}--icon-${icon}`,
            `${baseClassName}--theme-${theme}`
        )} style={stylePlus} {...props}/>
        {Boolean(tip) && (
            <Tippy reference={buttonRef} content={tip} placement={tipPlacement} delay={tipDelay}/>
        )}
    </>
}

export default DyTriggerIcon;
