import {GuardArgs} from "./routes";

export function pendingEmailVerification({fbUser}: GuardArgs) {

    if(!fbUser) {
        return '/auth'
    }

    if(fbUser?.emailVerified) {
        return '/dashboard';
    }
}

export function pendingApproval({fbUser, tenant}: GuardArgs): void | string {

    if(!fbUser) {
        return '/auth'
    }

    if(tenant?.approved) {
        return '/dashboard';
    }
}

export function verifyEmail({fbUser}: GuardArgs) {

    if(!fbUser) {
        return '/auth';
    }

    if(fbUser?.emailVerified) {
        return '/dashboard';
    }
}


export function authentication({ fbUser }: GuardArgs) {
    if (fbUser) {
        return '/dashboard'
    }
}

export function getStarted({ fbUser, tenant }: GuardArgs) {

    if(!fbUser) {
        return '/auth'
    }

    if (tenant?.displayName) {
        return '/dashboard'
    }
}


export function dashboard({ fbUser, tenant}: GuardArgs) {

    if (!fbUser) {
        return '/auth';
    }

    if(!fbUser.emailVerified) {
        return '/pending_email_verification';
    }

    if(tenant && !tenant.displayName) {
        return '/get_started';
    }

    if(tenant && !tenant?.approved) {
        return '/pending_approval';
    }
}
