import saveAs from "file-saver";
import {
    convertInchesToTwip,
    Document,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    TextRun,
    VerticalAlign
} from "docx";
import {PreparedField} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";
import {generatePreparedFields} from "@/services/field.service.ts";
import {capitalCase} from "change-case";
import {pdf} from "@react-pdf/renderer";
import {AbstractPdf} from "@/AbstractGenerator";
import React from "react";
import {camelCase} from "lodash";
import {getRelatedDocuments, RelatedDoculyDoc} from "@/api";

export async function generatePdfExtract(doc: DoculyDoc): Promise<void> {

    let relatedDocuments: RelatedDoculyDoc[] = []
    if(doc.hasRelatedDocuments) {
        relatedDocuments = await getRelatedDocuments(doc.id);
    }

    let address = getFieldValue(doc, 'leased_property_address');
    let tenantName = getFieldValue(doc, 'tenant_name');

    // try to pull address and tenant name from related documents if they don't existing on the primary
    for (let i = 0; i < relatedDocuments.length; i++) {
        if(address) break;
        address = getFieldValue(relatedDocuments[i], 'leased_property_address');
    }
    for (let i = 0; i < relatedDocuments.length; i++) {
        if(tenantName) break;
        tenantName = getFieldValue(relatedDocuments[i], 'tenant_name');
    }
    let suffix = address ?? tenantName ?? '';
    if(suffix) {
        suffix = ` - ${suffix}`;
    }
    const file = pdf(<AbstractPdf docs={[doc, ...relatedDocuments]} address={address} tenantName={tenantName}/>);
    saveAs(await file.toBlob(), `lease abstract${suffix}.pdf`);
}

function getFieldValue<T = string>(doc: DoculyDoc, key: string, defaultValue?: T): T | undefined {
    return doc.fields.find(field => field.key === key)?.value ?? defaultValue;
}

export async function generateDocxAbstract(doc: DoculyDoc): Promise<void> {

    const fields = generatePreparedFields(doc);
    const address = doc.fields.find(field => field.key === 'leased_property_address');
    const tenantName = doc.fields.find(field => field.key === 'tenant_name');
    // const layout = doc.l

    const docx = new Document({
        styles: {
          default: {
              document: {
                  run: {
                      font: 'Calibri'
                  }
              }
          }
        },
        sections: [{
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            size: 28,
                            text: 'Lease Abstract'
                        })
                    ]
                }),
                new Paragraph({}),
                new Paragraph({
                    children: [
                        new TextRun({
                            bold: true,
                            text: tenantName?.value
                        })
                    ]
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: address?.value
                        })
                    ]
                }),
                new Paragraph({}),

                new Table({
                    margins: {
                        top: convertInchesToTwip(0.025),
                        bottom: convertInchesToTwip(0.025),
                        left: convertInchesToTwip(0.025),
                        right: convertInchesToTwip(0.025),
                    },
                    rows: fields.map(field => new TableRow({
                        children: [
                            new TableCell({
                                children: [new Paragraph({text: field.displayName})],
                                verticalAlign: VerticalAlign.TOP,
                            }),
                            new TableCell({
                                children: fieldToChildren(field),
                                verticalAlign: VerticalAlign.TOP,
                            }),
                            new TableCell({
                                width: {size: '.75in'},
                                children: [new Paragraph({
                                    text: (field.value as DoculyField).srcPositions?.[0] ? `Page ${(field.value as DoculyField).srcPositions![0].page + 1}` : ''
                                } )],
                                verticalAlign: VerticalAlign.TOP,
                            })
                        ],
                    }))
                })
            ]
        }]
    });
    saveAs(await Packer.toBlob(docx), `lease abstract - ${address?.value}.docx`)
}

function fieldToChildren(field: PreparedField): any[] {

    if (field.config?.table) {
        const columns: string[] = field.config.columns;

        return [new Table({
            margins: {
                top: convertInchesToTwip(0.025),
                bottom: convertInchesToTwip(0.025),
                left: convertInchesToTwip(0.025),
                right: convertInchesToTwip(0.025),
            },
            width: {size: 100, type: "pct"},
            rows: [
                new TableRow({
                    children: columns.map(title => new TableCell({
                        width: {size: 1, type: "auto"},
                        children: [
                            new Paragraph({text: capitalCase(title)})
                        ]
                    }))
                }),
                // @ts-ignore
                ...(field.value as DoculyField<Record<string, DoculyField>>[]).map(field => (
                    new TableRow({
                        children: columns.map(column => new TableCell({
                            width: {size: 1, type: "auto"},
                            children: [
                                new Paragraph({
                                    text: `${field.value[camelCase(column)] ?? ''}`
                                })
                            ]
                        }))
                    })
                ))
            ]
        })]
    } else if (field.config?.aggregate && field.datatype === 'object') {
        return [new Table({
            margins: {
                top: convertInchesToTwip(0.025),
                bottom: convertInchesToTwip(0.025),
                left: convertInchesToTwip(0.025),
                right: convertInchesToTwip(0.025),
            },
            width: {size: 100, type: "pct"},
            rows: (field.value as DoculyField[]).map(field => (
                // @ts-ignore
                Object.entries((field as DoculyField<Record<string, DoculyField>>).value)
                    .map(([name, value]) => (
                        new TableRow({
                            children: [
                                new TableCell({
                                    width: {size: 1, type: 'auto'},
                                    children: [
                                        new Paragraph({text: capitalCase(name)})
                                    ]
                                }),
                                new TableCell({
                                    width: {size: 1, type: 'auto'},
                                    children: [
                                        new Paragraph({text: value as string})
                                    ]
                                }),
                            ]
                        })
                    ))
            )).flat()
        })]
    } else if (field.config?.aggregate) {
        return (field.value as DoculyField[]).map(field => new Paragraph({text: field.value}));
    }

    return [
        new Paragraph({text: (field.value as DoculyField).value})
    ]
}
