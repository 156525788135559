import './DyTextInput.scss';
import React, {HTMLInputTypeAttribute, ReactNode, useRef, useState} from "react";
import classnames from "classnames";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface DyTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    dyRef?: React.MutableRefObject<HTMLInputElement | undefined>;
    showSubmitTrigger?: boolean;

    // there's a better implementation of this
    onSubmit?(): void;

    enableEnterTrigger?: boolean;
    enableClear?: boolean;
    label?: ReactNode;
}

const baseClassName = 'dy-text-input';

export function DyTextInput(
    {
        className,
        dyRef,
        enableEnterTrigger,
        enableClear = false,
        showSubmitTrigger,
        onChange,
        onSubmit,
        label,
        type: originalType,
        ...props
    }: DyTextInputProps) {

    const inputRef: React.MutableRefObject<HTMLInputElement | undefined> = useRef<HTMLInputElement | undefined>();
    const [clearable, setClearable] = useState<boolean>(false);
    const [config, setConfig] = useState<{type: HTMLInputTypeAttribute | undefined, masked: boolean}>({
        type: originalType,
        masked: originalType === 'password'
    });

    function setRefs(el: HTMLInputElement) {
        inputRef.current = el;
        if (dyRef) {
            dyRef.current = el;
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        onChange?.(e);
        setClearable(enableClear && Boolean(e.target.value));
    }

    function handleSubmitTriggerClick(): void {
        onSubmit?.();
    }

    function handleClearTriggerClick(): void {
        inputRef.current!.value = '';
        setClearable(false);
    }

    function handleEyeClick(): void {
        setConfig(prevState => ({
            ...prevState,
            type: prevState.masked ? 'text' : 'password',
            masked: !prevState.masked
        }));
    }

    return <div className={classnames(baseClassName, className)}>
        {Boolean(label) && <div className={`${baseClassName}__label`}>{label}</div>}
        <div className={`${baseClassName}__input-wrapper`}>
            <input className={`${baseClassName}__input`} type={config.type} {...props}
                   onChange={handleChange} ref={setRefs}/>
            {clearable && <DyTriggerIcon icon={'clear'} onClick={handleClearTriggerClick}/>}
            {showSubmitTrigger && <DyTriggerIcon icon={'enter'} onClick={handleSubmitTriggerClick}/>}
            {originalType === 'password' && (
                <DyTriggerIcon icon={config.masked ? 'eye-open' : 'eye-closed'} onClick={handleEyeClick}/>
            )}
        </div>
    </div>
}

export default DyTextInput;
