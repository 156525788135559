import './DashboardNav.scss';
import React, {useMemo} from "react";
import classnames from "classnames";
import {useAppContext} from "@/contexts";
import {NavLink} from "react-router-dom";
import Tippy from "@tippyjs/react";
import {DyMenu, type DyMenuItemConfig} from "@/components/DyMenu";
import {dyConfirm} from '@/components/core/DyModal';
import {openInvitationCreator} from "@/components/InvitationCreator";
import {tenantAuth} from "@/services/auth.service.ts";

export interface DashboardNavProps extends React.HTMLAttributes<HTMLElement> {

}

const baseClassName = 'dashboard-nav';

export function DashboardNav({className, ...props}: DashboardNavProps) {
    const {user, fbUser, pendingReviewCount} = useAppContext();
    const userMenuItems = useMemo<DyMenuItemConfig[]>(() => [{
        label: 'Invite Team Members',
        async action() {
            console.log(await openInvitationCreator());
        }
    }, {
        label: 'Sign Out',
        async action() {
            if(await dyConfirm({
                title: 'Sign Out',
                content: 'Are you sure you want to sign out?',
                yesLabel: 'Yes',
                noLabel: 'No, Say Signed In'
            }))
            return tenantAuth.signOut();
        }
    }], []);

    return <div className={classnames(baseClassName, className)} {...props}>
        <nav className={`${baseClassName}__links`}>
            <NavLink to={'/dashboard/documents'}
                     className={({isActive}) => classnames(`${baseClassName}__link`, `${baseClassName}__link--documents`, {
                         [`${baseClassName}__link--active`]: isActive
                     })}>
                Documents
            </NavLink>
            <Tippy content={'Review issues found in your imported documents'} placement={'right'} delay={[500, 0]}>
                <NavLink to={'/dashboard/pending_reviews'}
                         className={({isActive}) => classnames(`${baseClassName}__link`, `${baseClassName}__link--pending-reviews`, {
                             [`${baseClassName}__link--active`]: isActive
                         })}>
                    <span>Review Issues</span>
                    {pendingReviewCount > 0 ? `(${pendingReviewCount})` : ''}
                </NavLink>
            </Tippy>
            <Tippy content={'Coming Soon'} placement={'right'} delay={[500, 0]}>
                <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--clauses`)}>Clauses</li>
            </Tippy>
            <Tippy content={'Coming Soon'} placement={'right'} delay={[500, 0]}>
                <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--alerts`)}>Alerts</li>
            </Tippy>
        </nav>
        <footer className={`${baseClassName}__footer`}>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--support`)}>Support</li>
            <li className={classnames(`${baseClassName}__link`, `${baseClassName}__link--settings`)}>Settings</li>
            <div className={`${baseClassName}__user`}>
                {Boolean(fbUser?.photoURL) && <img className={`${baseClassName}__user-pic`} src={fbUser!.photoURL!}/>}
                <div className={`${baseClassName}__user-name`}>{user?.firstName} {user?.lastName}</div>
                <DyMenu items={userMenuItems}>
                    <i className={`${baseClassName}__user-menu-trigger`}/>
                </DyMenu>
            </div>
        </footer>
    </div>
}

export default DashboardNav;
