import './VerifyEmail.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {useParams} from "react-router-dom";
import {DyDialog} from "@/components/core/DyDialog";
import {DyButton} from "@/components/core";
import {verifyAccount} from "@/api";

export interface VerifyEmailProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'verify-email';

export function VerifyEmail({className, ...props}: VerifyEmailProps) {
    const {uid} = useParams();
    useEffect(() => {
        if (!uid) return;

        (async() => {
            await verifyAccount(uid);

        })();
    }, [uid])


    return <div className={classnames(baseClassName, className)} {...props}>
        <DyDialog className={classnames(baseClassName, className)} {...props}>
            <DyDialog.LogoHeader />
            <DyDialog.Content className={classnames(`${baseClassName}__content`)}>
                <p>Your account is being verified.</p>
            </DyDialog.Content>
        </DyDialog>
    </div>
}

export default VerifyEmail;
