import './DoculyHeader.scss';
import React from "react";
import classnames from "classnames";
import logo from "@images/logos/logo-combo.svg";
import {DyTriggerIcon} from "@/components/core/DyTriggerIcon";

export interface DoculyHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose?(): void;
}

const baseClassName = 'doculy-header';

export function DoculyHeader({className, onClose, ...props}: DoculyHeaderProps) {

    function handleClose() {
        onClose?.();
    }

    return <header className={classnames(baseClassName, className)} {...props}>
        <img className={`${baseClassName}__image-logo`} src={logo} alt={'Doculy Logo'}/>
        {Boolean(onClose) && (
            <DyTriggerIcon icon={'close'} onClick={handleClose} className={`${baseClassName}__close-trigger`}/>
        )}
    </header>
}

export default DoculyHeader;
