import {routes} from "./routes/routes.tsx";
import {useRoutes} from "react-router";
import {useAppContext} from "@/contexts";
import {Wizard} from "@/components/importWizard";
import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import PopupContainer from "@/components/popups/PopupContainer.tsx";

function App() {
    const {initialized,loadingAccount} = useAppContext();
    const [searchParams] = useSearchParams()
    const element = useRoutes(routes);

    useEffect(() => {

        if (searchParams.get('popup')) {
            document.body.classList.add('with-popup');
        } else {
            document.body.classList.remove('with-popup');
        }
    }, [searchParams])

    if (!initialized || loadingAccount) return <></>

    return <>
        {element}
        <Wizard/>
        <PopupContainer/>
    </>
}

export default App
