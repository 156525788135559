import './InvitationViewer.scss';
import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import {acceptInvitation, getInvitationById} from "@/api";
import {dyAlert} from '@/components/core/DyModal'
import {DoculyHeader} from "@/components/DoculyHeader";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {DyButton, DyTextInput} from "@/components/core";
import {GoogleAuthProvider, signInWithCredential, signInWithEmailAndPassword} from "firebase/auth";
import {useAppContext} from "@/contexts";
import {tenantAuth} from "@/services/auth.service.ts";

export type InvitationProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'invitation-viewer';

type AccountInfo = {
    firstName: string;
    lastName: string;
    newPassword: string;
}

export function InvitationViewer({className, ...props}: InvitationProps) {

    const {refresh} = useAppContext();
    const navigate = useNavigate();
    const {invitationId} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [invitation, setInvitation] = useState<Invitation>();
    const [busy, setBusy] = useState<boolean>();
    const [accountInfo, setAccountInfo] = useState<AccountInfo>({
        firstName: '',
        lastName: '',
        newPassword: ''
    });
    const canSignUpWithEmail = useMemo<boolean>(() => {
        return Boolean(
            accountInfo.newPassword.trim() &&
            accountInfo.firstName.trim() &&
            accountInfo.lastName.trim()
        )
    }, [accountInfo])

    useEffect(() => {
        (async() => {
            const auth = tenantAuth
            if(auth.currentUser) {
                await auth.signOut();
            }
        })()
    }, []);

    useEffect(() => {

        if(!invitationId) return;
        (async() => {
            try {
                setLoading(true);
                const invitation = await getInvitationById(invitationId);
                setInvitation(invitation);
                setAccountInfo({
                    firstName: invitation.firstName,
                    lastName: invitation.lastName,
                    newPassword: ''
                })
            } catch(err: any) {
                if(err.code !== 'invitation_not_found') {
                    await dyAlert({
                        type: 'error',
                        title: 'Invitation Issues',
                        content: 'ERROR PLACE HOLDER'
                    });
                    navigate('/auth', {replace: true});
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [invitationId]);

    async function handleGoogleSignIn(response: CredentialResponse): Promise<void> {
        const credential = GoogleAuthProvider.credential(response.credential);
        try {
            setBusy(true);
            await signInWithCredential(tenantAuth, credential);
            await acceptInvitation(invitationId!);
            await refresh();
        } finally {
            setBusy(false);
        }
    }

    function handleAccountInfoChange({target}: ChangeEvent<HTMLInputElement>) {
        setAccountInfo(prevState => ({
            ...prevState,
            [target.name]: target.value
        }))
    }

    async function handleCreateAccount() {
        try {
            setBusy(true);
            await acceptInvitation(invitationId!, {
                firstName: accountInfo.firstName,
                lastName: accountInfo.lastName,
                password: accountInfo.newPassword,
            });
            await signInWithEmailAndPassword(tenantAuth, invitation!.email, accountInfo.newPassword)
        } finally {
            setBusy(false);
        }
    }

    function handleSignUp() {
        navigate('/auth/sign_up', {replace: true});
    }

    if(loading) return <></>

    if(!invitation) return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <p>Sorry, we looked everywhere but couldn't find this invitation.</p>
            <p>If you think this is a mistake, please contact our <a href={'mailto:'}>customer success team</a>.</p>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleSignUp} busy={busy}>
                Sign Up For An Account
            </DyButton>
        </footer>
    </div>

    return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <p>You've been invited to join the <span className={`${baseClassName}__workspace-name`}>
            {invitation.tenantDisplayName}
            </span> workspace!</p>
            <p>Just sign in using your Google account or fill out the form to join.</p>
            <GoogleLogin containerProps={{className: `${baseClassName}__google-btn`}}
                         onSuccess={handleGoogleSignIn}
                         text={'continue_with'}
                         theme={"filled_blue"} shape={'rectangular'} width={'400'}/>
            <div className={`${baseClassName}__or`}>or</div>
            <div className={`${baseClassName}__account-info`}>
                <DyTextInput label={'First Name'} name={'firstName'} onChange={handleAccountInfoChange}
                             value={accountInfo.firstName}/>
                <DyTextInput label={'Last Name'} name={'lastName'} onChange={handleAccountInfoChange}
                             value={accountInfo.lastName}/>
                <DyTextInput label={'Password'} name={'newPassword'} type="password" autoComplete={'new-password'}
                             onChange={handleAccountInfoChange} value={accountInfo.newPassword}/>
            </div>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleCreateAccount} disabled={!canSignUpWithEmail} busy={busy}>
                Accept Invitation
            </DyButton>
        </footer>
    </div>
}

export default InvitationViewer;
