import './SignUp.scss';
import React, {ChangeEvent, useMemo, useState} from "react";
import classnames from "classnames";
import {DyButton, DyTextInput} from "../core";
import {createAccountWithEmailAndPassword, createAccountWithGoogle} from "../../api/account.api.ts";
import {GoogleAuthProvider, signInWithCredential, signInWithEmailAndPassword} from "firebase/auth";
import {DoculyHeader} from "@/components/DoculyHeader";
import {Link, NavLink} from "react-router-dom";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {dyAlert} from "@/components/core/DyModal";
import {tenantAuth} from "@/services/auth.service.ts";

export type SignUpProps = React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'sign-up';

interface SignUpUser {
    firstName: string;
    lastName: string;
    newEmail: string;
    newPassword: string;
    companyName: string;
    industry: string;
}

export function SignUp({className, ...props}: SignUpProps) {

    const [accountInfo, setAccountInfo] = useState<SignUpUser>({
        firstName: '',
        newEmail: '',
        lastName: '',
        newPassword: '',
        industry: '',
        companyName: ''
    });
    const [busy, setBusy] = useState<boolean>(false);
    const canSignUpWithEmail = useMemo<boolean>(() => {

        const {firstName, lastName, newPassword, newEmail} = accountInfo;
        return Boolean(
            firstName.trim() &&
            lastName.trim() &&
            newEmail.trim() &&
            newPassword.trim()
        );

    }, [accountInfo]);


    function handleUserChange(e: ChangeEvent<HTMLInputElement>): void {

        setAccountInfo(user => ({
                ...user,
                [e.target.name]: e.target.value
            })
        )
    }

    async function handleGoogleSignIn(response: CredentialResponse) {
        const credential = GoogleAuthProvider.credential(response.credential);
        const {user} = await signInWithCredential(tenantAuth, credential);
        if(!user.emailVerified) {
            await createAccountWithGoogle();
        }
    }

    async function handleCreateAccount() {
        if (!canSignUpWithEmail) return;

        try {
            setBusy(true)
            await createAccountWithEmailAndPassword({
                email: accountInfo.newEmail,
                password: accountInfo.newPassword,
                firstName: accountInfo.firstName,
                lastName: accountInfo.lastName,
            });
            await signInWithEmailAndPassword(tenantAuth, accountInfo.newEmail, accountInfo.newPassword);
        } catch (err: any) {

            switch(err.code) {
                case 'duplicate_user':
                    dyAlert({
                        type: 'error',
                        title: 'Email Already Exists',
                        content: <>
                            <p>An account with the email <span className={`${baseClassName}__`}>
                                {accountInfo.newEmail}
                            </span> already exists.</p>
                            <p>Try logging in using the email and the last password you can remember.</p>
                            <p>If you can't remember your password you can <Link to={'/reset_password'}>reset it here
                            </Link> or contact our <a href={'mailto:'}>customer success team</a>.</p>
                        </>
                    });
                    break;
                default:
                    dyAlert({
                        type: 'error',
                        title: 'Sign Up Failed',
                        content: <>
                            <p>We ran into an unexpected snag while creating your account.</p>
                            <p>Please try again in a few minutes or contact our <a href={'mailto:'}>customer success team</a>.</p>
                        </>
                    })
            }
        } finally {
            setBusy(false);
        }
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <GoogleLogin containerProps={{className: `${baseClassName}__google-btn`}}
                         onSuccess={handleGoogleSignIn}
                         text={'signup_with'}
                         theme={"filled_blue"} shape={'rectangular'} width={'400'}/>
            <div className={`${baseClassName}__or`}>or</div>
            <div className={`${baseClassName}__account-info`}>
                <DyTextInput label={'First Name'} name={'firstName'} onChange={handleUserChange}
                             value={accountInfo.firstName}/>
                <DyTextInput label={'Last Name'} name={'lastName'} onChange={handleUserChange}
                             value={accountInfo.lastName}/>
                <DyTextInput label={'Email'} name={'newEmail'} autoComplete={'new-password'}
                             onChange={handleUserChange} value={accountInfo.newEmail}/>
                <DyTextInput label={'Password'} name={'newPassword'} type="password" autoComplete={'new-password'}
                             onChange={handleUserChange} value={accountInfo.newPassword}/>
            </div>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleCreateAccount} disabled={!canSignUpWithEmail} busy={busy}>
                Sign Up With Email And Password
            </DyButton>
            <NavLink className={`${baseClassName}__mode-toggle`} to={'/auth/sign_in'} replace={true}>
                Already have an account? Sign In.
            </NavLink>
        </footer>
    </div>
}

export default SignUp;
