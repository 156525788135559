import './FileUploadList.scss';
import React from "react";
import classnames from "classnames";
import {FileUploadMonitor} from "@/components/importWizard/FileUploadMonitor/FileUploadMonitor.tsx";
import {FileUpload, useFileUpload} from "@/components/importWizard/FileUploadMonitor/FileUploadContext.ts";
import {AnimatePresence} from "framer-motion";

export interface FileUploadListProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'file-upload-list';

export function FileUploadList({className, ...props}: FileUploadListProps) {

    const {uploads, removeUpload} = useFileUpload()!;

    function handleDelete(upload: FileUpload): void {
        removeUpload(upload.file)
    }

    if (!uploads.length) return <></>;

    return <AnimatePresence>
        <div className={classnames(baseClassName, className)} {...props}>
            {uploads?.map((upload) => (
                <FileUploadMonitor key={upload.file.name} upload={upload} onDelete={handleDelete}
                                   transition={{ ease: "easeIn", duration: .5, delay: .25 }}
                                   initial={{ opacity: 0, scale: .5 }}
                                   animate={{ opacity: 1, scale: 1 }}
                                   exit={{ opacity: 0, scale: .5 }}/>
            ))}
        </div>
    </AnimatePresence>
}

export default FileUploadList;
