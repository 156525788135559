import {useNavigate, useSearchParams} from "react-router-dom";

export function useConfidenceScore() {
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    return {
        open() {
            setSearchParams(params => {
                params.set('popup', 'score_definitions');
                return params
            });
        },
        close() {
            navigate(-1);
        }
    }
}
