import './DocumentListMenuCellRenderer.scss';
import {ICellRendererParams} from "@ag-grid-community/core";
import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {
    downloadDocument,
    exportFieldsAsCsv,
    exportFieldsAsExcel,
    generateDocxAbstract,
    generatePdfExtract
} from "@/services";
import Tippy from "@tippyjs/react";
import {useNavigate} from "react-router-dom";
import {linkDocuments, normalizedFields} from "@/api";
import {Instance} from "tippy.js";
import {toast} from "react-toastify";
import {dyPrompt} from "@/components/core/DyModal";
import {useAppContext} from "@/contexts";

const baseClassName = 'document-list-menu-cell-renderer';

type MenuItemConfig = {
    name: string;
    include?: boolean;
    action(props: ICellRendererParams): Promise<void> | void;
}

export function DocumentListMenuCellRenderer(props: ICellRendererParams) {

    const {fbUser} = useAppContext();
    const navigate = useNavigate();
    const menuRef = useRef<Instance | null>(null);
    const [claims, setClaims] = useState<Record<string, any>>({});
    const content = useMemo<ReactNode>(() => {

        const {data = {}} = props;
        const {enableExperiments = false} = claims;
        const options: MenuItemConfig[] = [
            {
                name: 'Download Original File',
                action({node}) {
                    if (!node?.data) return;
                    downloadDocument(node.data);
                }
            }, {
                name: 'Export Fields As Excel',
                action({node}) {
                    if (!node?.data) return;
                    exportFieldsAsExcel(node.data);
                }
            }, {
                name: 'Export Fields As CSV',
                action({node}) {
                    if (!node?.data) return;
                    exportFieldsAsCsv(node.data);
                }
            }, {
                name: 'Generate Abstract As PDF',
                include: props.data?.docType === 'lease_agreement',
                action() {
                    generatePdfExtract(data);
                }
            }, {
                name: 'Generate Abstract As DOCX',
                include: enableExperiments && data.docType === 'lease_agreement',
                action() {
                    generateDocxAbstract(data);
                }
            }, {
                name: data.requiresReview ? 'Resolve Import Issues' : 'Edit Fields',
                action() {
                    navigate(`/dashboard/extract/${data.id}`)
                }
            }, {
                name: 'Reprocess Extracted Fields',
                include: enableExperiments,
                async action() {
                    toast('We have started reprocessing your extracted fields.');
                    try {
                        const newData = await normalizedFields(data.id);
                        props.node.setData(newData);
                        toast('Reprocessing complete.');
                    } catch (err) {
                        // handle errors
                    }
                }
            }, {
                name: 'Attach As Addendum',
                async action() {
                    try {
                        const id = await dyPrompt({
                            title: 'Attach As Amendment',
                            content: 'Enter the id of the Lease Agreement.',
                            submitLabel: 'Attach'
                        });

                        await linkDocuments(id, data.id, 'amendment');

                    } catch (err) {
                        // handle errors
                    }
                }
            }
        ]

        function handleClick({action}: MenuItemConfig): void {
            menuRef.current?.hide();
            action(props);
        }

        return <div className={baseClassName}>
            <menu>
                {options.filter(o => o.include !== false).map((option, i) => (
                    <li key={i} onClick={() => handleClick(option)}>
                        {option.name}
                    </li>
                ))}
            </menu>
        </div>
    }, [props.data, claims]);

    useEffect(() => {
        if(!fbUser) return;

        (async() => {
            const {claims} = await fbUser.getIdTokenResult();
            setClaims(claims);
        })();
    }, [fbUser])

    function handleMenuShown(instance: Instance) {
        menuRef.current = instance;
    }

    return (
        <Tippy placement={'left-start'}
               trigger={'click'}
               interactive={true}
               onShown={handleMenuShown}
               className={`${baseClassName}__menu`}
               arrow={false}
               appendTo={document.body}
               content={content}>
            <div className={`${baseClassName}__trigger`}/>
        </Tippy>
    )
}
