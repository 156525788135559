import {initializeApp} from 'firebase/app';
import {firebaseConfig} from "./config";
import {logRocketConfig} from "@/config/logrocket.config.ts";
import {mixpanelConfig} from "@/config/mixpanel.config.ts";
import mixpanel from "mixpanel-browser";
import logRocket from "logrocket";
import {pdfjs} from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const logRocketEnabled: boolean = Boolean(logRocketConfig.appId);
const mixPanelEnabled: boolean = Boolean(mixpanelConfig.token);

navigator.serviceWorker.register(`/sw.js`, {
    scope: '/'
});

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

initializeApp(firebaseConfig);

if (mixPanelEnabled) {
    mixpanel.init(mixpanelConfig.token, {
        track_pageview: 'full-url'
    });
}

if (logRocketEnabled) {
    logRocket.init(logRocketConfig.appId);
}
