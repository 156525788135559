import './Dashboard.scss';
import React, {useEffect} from "react";
import classnames from "classnames";
import {DashboardHeader, DashboardNav} from "../../components/dashboard";
import {Outlet} from "react-router";
import {useMessages} from "@/contexts/useMessages.ts";
import {toast} from "react-toastify";
import {DyButton} from "@/components/core";
import {useNavigate} from "react-router-dom";

export interface DashboardProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'dashboard';

export function Dashboard({className, ...props}: DashboardProps) {

    const {messages} = useMessages();
    const navigate = useNavigate();

    useEffect(() => {
        const [message] = messages;
        if (message) {
            toast(<div className={'dy-toast'}>
                <main>
                    <h1>{message.message}</h1>
                </main>
                <footer>
                    <DyButton onClick={() => {
                        navigate(`/dashboard/review/${message.data?.id}`)
                    }}>Start Review</DyButton>
                </footer>
            </div>, {
                autoClose: false,
                hideProgressBar: true
            });
        }
    }, [messages])

    return <div className={classnames(baseClassName, className)} {...props}>
        <DashboardHeader className={`${baseClassName}__header`}/>
        <DashboardNav className={`${baseClassName}__nav`}/>
        <main className={`${baseClassName}__content`}>
            <Outlet/>
        </main>
    </div>
}

export default Dashboard;
