import logRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import {useAppContext} from "@/contexts";
import React, {ReactNode, useEffect, useState} from "react";
import {mixpanelConfig} from "@/config/mixpanel.config.ts";
import {logRocketConfig} from "@/config/logrocket.config.ts";

const logRocketEnabled: boolean = Boolean(logRocketConfig.appId);
const mixPanelEnabled: boolean = Boolean(mixpanelConfig.token);

export function useAnalytics() {

}

const AnalyticsContext = React.createContext({});

interface AppAnalyticsProps {
    children?: ReactNode | undefined;
}

export function AppAnalytics({children}: AppAnalyticsProps) {

    const {user} = useAppContext();
    const [value] = useState({});

    useEffect(() => {

        if (user) {
            const id = String(user.id);
            const {email, firstName, lastName, tenantId} = user;
            const name = `${firstName} ${lastName}`;

            if(logRocketEnabled) {
                logRocket.identify(id, {
                    email,
                    name,
                    tenantId
                });
            }

            if(mixPanelEnabled) {
                mixpanel.set_group('Tenant', tenantId);
                mixpanel.identify(id);
                mixpanel.people.set({
                    ...user,
                    $email: email,
                    $first_name: firstName,
                    $last_name: lastName,
                    email: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    company: undefined,
                })
                mixpanel.register({
                    userId: user.id,
                    tenantId: tenantId
                });
            }

        } else {
            if(logRocketEnabled) {
                logRocket.identify('anonymous');
            }

            if(mixPanelEnabled) {
                mixpanel.reset();
            }
        }
    }, [user]);

    return <AnalyticsContext.Provider value={value}>
        {children}
    </AnalyticsContext.Provider>
}
