import {useSearchParams} from "react-router-dom";

export function useWizard() {

    const [, setSearchParams] = useSearchParams();

    return {
        openWizard() {
            setSearchParams([['wizardStep', 'choose']], {replace: true})
        }
    }
}
