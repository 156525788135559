import {defaultAuthenticatedApiClient} from "@/api/ApiClient.ts";
import {capitalCase} from "change-case";

export async function getCurrentDocumentTypes(): Promise<string[]> {

    return await defaultAuthenticatedApiClient.httpGet<string[]>('/my/document_types');
}

export async function getDocuments(type?: string): Promise<DoculyDoc[]> {

    const rawDocuments = await defaultAuthenticatedApiClient.httpGet<any[]>('/documents', {
        params: {
            type
        }
    });

    return rawDocuments.map(toDoculyDoc);
}

export interface DoculyResult {
    type: 'answer' | 'documents';
    answer?: string;
    documents?: DoculyDoc[];
}

export async function ask(question: string, type?: string): Promise<DoculyResult> {

    const result = await defaultAuthenticatedApiClient.httpPost('/documents.search', {
        question,
        documentType: type
    });

    return {
        ...result,
        documents: result.documents?.map(doc => toDoculyDoc(doc))
    };
}

export async function updateField(docId: number, fieldId: number, key: string, value: any): Promise<UserDocumentField> {
    return defaultAuthenticatedApiClient.httpPatch(`/documents/${docId}/fields/${fieldId}`, {
        key,
        value
    })
}

export async function updateFields(id: number, fields: {id: number, value: string}[]): Promise<DoculyField[]> {
    return defaultAuthenticatedApiClient.httpPost(`/documents.bulk_update_fields`, {
        id,
        fields
    })
}

export async function addField(docId: number, key: string, value: any): Promise<UserDocumentField> {
    return defaultAuthenticatedApiClient.httpPost(`/documents/${docId}/fields`, {
        key,
        value
    })
}

export async function removeField(docId: number, fieldId: number): Promise<void> {
    await defaultAuthenticatedApiClient.httpDelete(`/documents/${docId}/fields/${fieldId}`);
}

export type SourcePosition = {
    page: number;
    x: number;
    y: number;
    width: number;
    height: number;
}

export type UserDocumentField = {
    id: number;
    field: string;
    value: any;
    type: string;
    srcImage: string;
    srcFieldId: number;
    srcPositions: SourcePosition[];
    issue?: string;
}

export type UserDocument = {
    id: number;
    filename: string;
    type: string;
    requiresReview: boolean;
    fields: UserDocumentField[];
    srcUrl: string;
}


export async function getDocumentById(id: number): Promise<DoculyDoc> {
    const rawDoc = await defaultAuthenticatedApiClient.httpGet(`/documents/${id}`);
    return toDoculyDoc(rawDoc);
}

export async function getReviewDocument(id: number): Promise<UserDocument> {
    return defaultAuthenticatedApiClient.httpGet(`/documents/${id}`);
}

export async function normalizedFields(id: number): Promise<DoculyDoc> {

    const rawDoc = await defaultAuthenticatedApiClient.httpPost('/documents.normalize_fields', {
        id
    });
    return toDoculyDoc(rawDoc);
}

export async function linkDocuments(parentId: number, childId: number, type: string = 'child'): Promise<any> {
    // todo: change this to use related_documents and support creating both child and parent relationships
    await defaultAuthenticatedApiClient.httpPost(`/documents/${parentId}/children`, {
        childId,
        type
    });
}

export type RelatedDoculyDoc = DoculyDoc & {
    relationship: string,
    role: 'child' | 'parent'
}

// todo: add support for filtering by type of relationship and role (parent/child)
export async function getRelatedDocuments(id: number): Promise<RelatedDoculyDoc[]> {
    const docs = await defaultAuthenticatedApiClient.httpGet(`/documents/${id}/related_documents`);

    return docs.map(toDoculyDoc);
}



// todo: add type
function toDoculyDoc({fields, createdAt, updatedAt, ...document}: any): DoculyDoc {

    return {
        ...document,
        docType: document.type ?? 'Unknown',
        fields: fields.map(toDoculyField),
        updatedAt: updatedAt ? new Date(updatedAt) : undefined,
        createdAt: createdAt ? new Date(createdAt) : undefined,
    };
}

function toDoculyField({srcFieldId, field, score, srcPositions, type, value}: any): DoculyField {
    return {
        id: srcFieldId,
        score,
        key: field,
        displayName: field ? capitalCase(field) : 'Unknown',
        datatype: type,
        rawValue: value,
        srcPositions,
        value
    }
}

