import './DocumentList.scss';
import React, {useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import {DyButton} from "@/components/core";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {DocumentImporter} from "@/components/importWizard/DocumentImporter";
import {AgGridReact} from "@ag-grid-community/react";
import {ColDef, ModuleRegistry,} from "@ag-grid-community/core";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {useWizard} from "@/components/importWizard";
import {getDocumentById, getDocuments} from "@/api";
import {useAppContext, useServerEvents} from "@/contexts";
import DocumentListDetail from "@/components/DocumentList/DocumentListDetail.tsx";
import {capitalCase} from "change-case";
import {ClipboardModule} from "@ag-grid-enterprise/clipboard";
import {DocumentListMenuCellRenderer} from "@/components/DocumentList/DocumentListMenuCellRenderer.tsx";
import DocumentTypeCellRenderer from "@/components/DocumentList/DocumentTypeCellRenderer.tsx";
import {dyConfirm} from "@/components/core/DyModal";
import {subscribeToNotifications} from "@/services";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    MasterDetailModule,
    // MenuModule,
    ClipboardModule
]);

export interface DocumentListProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'document-list';

const defaultColDef: ColDef = {
    suppressHeaderContextMenu: true
}

export function DocumentList({className, ...props}: DocumentListProps) {

    const {events} = useServerEvents();
    const {type} = useParams();
    const {searchResults, allDocumentTypes} = useAppContext();
    const navigate = useNavigate();
    const [wizardOpen, setWizardOpen] = useState<boolean>(false);
    const [documents, setDocuments] = useState<DoculyDoc[]>([]);
    const {openWizard} = useWizard();
    const gridRef = useRef(null);

    const components = useMemo(() => ({
        menuCellRenderer: DocumentListMenuCellRenderer,
        docTypeCellRenderer: DocumentTypeCellRenderer,
    }), []);
    const supportedDocTypes = useMemo<DisplayValuePair[]>(() => {

        let supportedDocTypes: DisplayValuePair[] = [];
        if (allDocumentTypes) {
            supportedDocTypes = allDocumentTypes.map(value => ({
                display: capitalCase(value),
                value
            }));
        }
        return [
            {display: 'All', value: 'all'},
            ...supportedDocTypes
        ];
    }, [allDocumentTypes]);

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = useMemo<ColDef[]>(() => [
        {
            cellRenderer: "agGroupCellRenderer",
            width: 40,
            cellClass: `${baseClassName}__trigger-col`,
            headerClass: `${baseClassName}__trigger-col`,
            sortable: false, pinned: 'left'
        },
        {
            field: "id",
            headerName: "ID",
            width: 65,
            suppressHeaderContextMenu: true,
            sort: 'desc'
            // cellClass: `${baseClassName}__1st-col`,
            // headerClass: `${baseClassName}__1st-col`
        },
        {headerName: "Type", cellRenderer: 'docTypeCellRenderer'},
        {headerName: 'Company', valueGetter: ({data}) => data.fields.find(f => f.key === 'landlord_name')?.value},
        {field: "filename", headerName: 'File Name', flex: 1},
        {headerName: 'Signature Date'},
        {field: "updatedAt", headerName: 'Last Update', cellDataType: 'date'},
        {
            headerName: 'Uploaded By',
            valueGetter({data}) {
                return data?.createdBy ? `${data.createdBy?.firstName} ${data.createdBy?.lastName}` : 'anonymous'
            }
        },
        {
            cellRenderer: "menuCellRenderer",
            width: 40,
            cellClass: `${baseClassName}__menu-col`,
            headerClass: `${baseClassName}__menu-col`,
            sortable: false, pinned: 'right'
        },
    ], []);

    useEffect(() => {
        if (type === 'all' || allDocumentTypes?.includes(type!)) {
            (async () => {
                setDocuments(await getDocuments(type));
            })();
        } else if (type === 'search') {
            setDocuments(searchResults ?? []);
        } else {
            navigate('/dashboard/documents/all', {
                replace: true
            })
        }
    }, [type]);

    useEffect(() => {
        setDocuments(searchResults ?? []);
        if (searchResults?.length) {
            navigate('/dashboard/documents/search', {
                replace: true
            })
        }

    }, [searchResults]);

    useEffect(() => {
        events.forEach(event => {
            switch (event?.type) {
                case 'document.added':
                case 'document.processed':
                case 'document.processing_failed':
                    refreshDocument(event.body.id);
                    break;
            }
        });
    }, [events])

    async function refreshDocument(id: number) {

        const document = await getDocumentById(id)
        setDocuments(documents => {

            if (!document) {
                return documents.filter(d => d.id !== id);
            } else if (documents.some(d => d.id === id)) {
                // if it's an existing document replace it
                return documents.map(d => d.id === id ? document : d)
            } else {
                // if it's a new document append it
                return [...documents, document]
            }
        })
    }

    function handleAdd() {
        // setWizardOpen(true);
        openWizard();
    }

    async function handleWizardClose(): Promise<void> {

        if (Notification.permission === 'default' &&
            await dyConfirm({
                title: 'Get Notified',
                content: <>
                    <p>Enable push notifications and we'll let you know once your files have finished processing.</p>
                </>,
                yesLabel: 'Yes, Enable',
                noLabel: 'No Thanks'
            }) &&
            await Notification.requestPermission() === 'granted'
        ) {
            await subscribeToNotifications();
        }
        setWizardOpen(false);
    }

    return <>
        <div className={classnames(baseClassName, className)} {...props}>
            <header className={`${baseClassName}__header`}>
                <h1>Documents</h1>
                <h2>Create, store, track and manage your documents.</h2>
                <div className={`${baseClassName}__actions`}>
                    <DyButton className={`${baseClassName}__export-btn`} dyType={'secondary'}>Export</DyButton>
                    <DyButton className={`${baseClassName}__add-btn`} onClick={handleAdd}>Add Document(s)</DyButton>
                </div>
            </header>
            <ul className={`${baseClassName}__tabs`}>
                {supportedDocTypes.map(({display, value}) => (
                    <NavLink key={value} to={`/dashboard/documents/${value}`} role={'tab'}
                             className={({isActive}) => classnames(`${baseClassName}__tab`, {
                                 [`${baseClassName}__tab--active`]: isActive

                             })}>
                        {display}
                    </NavLink>
                ))}
                {Boolean(searchResults) && (
                    <NavLink key={'search'} to={`/dashboard/documents/search`} role={'tab'}
                             className={({isActive}) => classnames(`${baseClassName}__tab`, {
                                 [`${baseClassName}__tab--active`]: isActive

                             })}>
                        Search Results
                    </NavLink>
                )}
            </ul>
            <main className={classnames(`${baseClassName}__content`, 'ag-theme-quartz')}>
                <AgGridReact
                    ref={gridRef}
                    components={components}
                    detailRowAutoHeight={true}
                    suppressCellFocus={true}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    overlayNoRowsTemplate={'There are no documents available.'}
                    rowData={documents}
                    masterDetail={true}
                    detailCellRenderer={DocumentListDetail}
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}/>
            </main>
        </div>
    </>
}

export default DocumentList;
