import './WizardActionSelector.scss';
import React from "react";
import classnames from "classnames";
import {DyButton} from "@/components/core";
import {DyDialog} from "@/components/core/DyDialog";

export interface UploadWizardProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose(): void;
    onChoice?(choice: string): void;
}

const baseClassName = 'upload-wizard';

export function WizardActionSelector({className,onChoice, onClose, ...props}: UploadWizardProps) {

    if (!open) return <></>

    return <DyDialog className={classnames(`${baseClassName}__wrapper`, className)} {...props}>
        <DyDialog.LogoHeader onClose={onClose}/>
        <DyDialog.Message>
            <p>👋 Hey there, I'm Doculy AI. I’m here to make your experience smooth and hassle-free.</p>
            <p>What document related task can I help you with today?</p>
        </DyDialog.Message>
        <DyDialog.Actions>
            <DyButton dyType={'navigation'}
                      onClick={() => onChoice?.('import')}
                      className={classnames(`${baseClassName}__action`, `${baseClassName}__action--extract`)}>
                Extract key information from document(s)
            </DyButton>
            <DyButton dyType={'navigation'}
                      disabled
                      onClick={() => onChoice?.('create')}
                      className={classnames(`${baseClassName}__action`, `${baseClassName}__action--create`)}>
                Create document from example(s)
            </DyButton>
        </DyDialog.Actions>
    </DyDialog>
}

export default WizardActionSelector;
