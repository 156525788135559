import './TenantSetup.scss';
import React, {ChangeEvent, useRef, useState} from "react";
import classnames from "classnames";
import {DyDialog} from "@/components/core/DyDialog";
import {DyButton, DyTextInput} from "@/components/core";
import Select from "react-select";
import {TenantUpdateParams, updateTenant} from "@/api";
import {toast} from "react-toastify";
import {useAppContext} from "@/contexts";

export interface TenantSetupProps extends React.HTMLAttributes<HTMLDivElement> {

}

const INDUSTRY_OPTIONS: DisplayValuePair[] = [
    'Real Estate',
    'Public Sector',
    'Other'
].map(v => ({display: v, value: v}));

const baseClassName = 'tenant-setup';

export function TenantSetup({className, ...props}: TenantSetupProps) {

    const {tenant, refresh} = useAppContext();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [config, setConfig] = useState<TenantUpdateParams>({
        displayName: tenant?.displayName,
        industry: tenant?.industry
    });

    function handleValueChange(e: ChangeEvent<HTMLInputElement>): void {

        setConfig(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            })
        )
    }

    function handleIndustryChange(value: DisplayValuePair | null): void {

        setConfig(prevState => ({
            ...prevState,
            industry: value?.value ?? ''
        }))
    }

    async function handleSave() {
        await updateTenant(config);
        toast('Company information saved.');
        refresh();
    }

    return <div className={classnames(baseClassName, className)} {...props} ref={wrapperRef}>
        <DyDialog className={classnames(baseClassName, className)} {...props}>
            <DyDialog.LogoHeader/>
            <DyDialog.Message>We just need a bit of information about your company.</DyDialog.Message>
            <DyDialog.Content className={classnames(`${baseClassName}__content`)}>
                <div className={`${baseClassName}__label`}></div>
                <DyTextInput className={`${baseClassName}__name`}
                             label={`What's the name of your company?`}
                             name={'displayName'}
                             type={'text'}
                             onChange={handleValueChange} value={config.displayName ?? ''}/>

                {/*<div className={`${baseClassName}__label`}>What industry are you in?</div>*/}
                {/*<Select name={'industry'} onChange={handleIndustryChange}*/}
                {/*        getOptionValue={v => v.value}*/}
                {/*        getOptionLabel={v => v.display}*/}
                {/*        menuPortalTarget={document.body}*/}
                {/*        minMenuHeight={100}*/}
                {/*        options={INDUSTRY_OPTIONS}*/}
                {/*        value={INDUSTRY_OPTIONS.find(v => v.value === config.industry)}/>*/}
            </DyDialog.Content>
            <DyDialog.Actions className={`${baseClassName}__actions`}>
                <DyButton onClick={handleSave} disabled={!config.displayName?.trim()}>
                    Save
                </DyButton>
            </DyDialog.Actions>
        </DyDialog>

    </div>
}

export default TenantSetup;
