import './AbstractGenerator.scss';
import React, {useMemo} from "react";
import {Style} from '@react-pdf/types';
import {Document, Font, Page, Path, StyleSheet, Svg, Text, View} from '@react-pdf/renderer';
import {AggregatedField, FieldConfig} from "@/components/DocumentFieldValue/DocumentFieldValue.types.ts";
import {groupAndPrepareFields} from "@/services";
import {camelCase, snakeCase} from "lodash";
import interThin from '@/fonts/Inter/Inter-Thin.ttf';
import interSemiBold from '@/fonts/Inter/Inter-SemiBold.ttf';
import interBold from '@/fonts/Inter/Inter-Bold.ttf';
import interBlack from '@/fonts/Inter/Inter-Black.ttf';
import interNormal from '@/fonts/Inter/Inter-Regular.ttf';
import interMedium from '@/fonts/Inter/Inter-Medium.ttf';
import interLight from '@/fonts/Inter/Inter-Light.ttf';
import interExtraLight from '@/fonts/Inter/Inter-ExtraLight.ttf';
import interExtraBold from '@/fonts/Inter/Inter-ExtraBold.ttf';
import interThinItalic from '@/fonts/Inter/Inter-ThinItalic.ttf';
import interSemiBoldItalic from '@/fonts/Inter/Inter-SemiBoldItalic.ttf';
import interBoldItalic from '@/fonts/Inter/Inter-BoldItalic.ttf';
import interBlackItalic from '@/fonts/Inter/Inter-BlackItalic.ttf';
import interNormalItalic from '@/fonts/Inter/Inter-Italic.ttf';
import interMediumItalic from '@/fonts/Inter/Inter-MediumItalic.ttf';
import interLightItalic from '@/fonts/Inter/Inter-LightItalic.ttf';
import interExtraLightItalic from '@/fonts/Inter/Inter-ExtraLightItalic.ttf';
import interExtraBoldItalic from '@/fonts/Inter/Inter-ExtraBoldItalic.ttf';
import {formatValue} from "@/utils/formatter.ts";
import {capitalCase} from "change-case";

// Create styles
Font.registerHyphenationCallback(word => [word]);
Font.register({
    family: 'Inter',
    fonts: [{
        fontStyle: 'normal',
        fontWeight: 100,
        src: interThin
    }, {
        fontStyle: 'normal',
        fontWeight: 200,
        src: interExtraLight
    }, {
        fontStyle: 'normal',
        fontWeight: 300,
        src: interLight
    }, {
        fontStyle: 'normal',
        fontWeight: 400,
        src: interNormal
    }, {
        fontStyle: 'normal',
        fontWeight: 500,
        src: interMedium
    }, {
        fontStyle: 'normal',
        fontWeight: 600,
        src: interSemiBold
    }, {
        fontStyle: 'normal',
        fontWeight: 700,
        src: interBold
    }, {
        fontStyle: 'normal',
        fontWeight: 800,
        src: interExtraBold
    }, {
        fontStyle: 'normal',
        fontWeight: 900,
        src: interBlack
    }, {
        fontStyle: 'italic',
        fontWeight: 100,
        src: interThinItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 200,
        src: interExtraLightItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 300,
        src: interLightItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 400,
        src: interNormalItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 500,
        src: interMediumItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 600,
        src: interSemiBoldItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 700,
        src: interBoldItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 800,
        src: interExtraBoldItalic
    }, {
        fontStyle: 'italic',
        fontWeight: 900,
        src: interBlackItalic
    }]
})
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        fontSize: 10,
        fontFamily: 'Inter',
        paddingBottom: '.75in',
    },
    pageTitle: {
        fontSize: 14,
        fontWeight: 800,
        marginBottom: 6,
        textTransform: 'uppercase',
        color: '#149cff',
    },
    section: {
        margin: 2,
        marginBottom: 8,
        fontFamily: 'Inter'
    },
    sectionLabel: {
        marginBottom: 3,
        fontWeight: 800,
        fontFamily: 'Inter',
        textTransform: 'uppercase',
        color: '#149cff',
    },
    table: {
        display: "flex",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#000',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderTopWidth: 1,
    },
    tableRow: {
        margin: 0,
        flexDirection: "row",
        width: '100%',
        borderStyle: "solid",
        borderColor: '#000',
        borderWidth: 0,
        borderLeftWidth: 1,
    },
    labelCol: {
        fontWeight: 'bold',
        width: "2in",
        flexShrink: 0,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#000',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 4,
    },
    valueCol: {
        flexGrow: 1,
        flexShrink: 1,
        width: 0,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#000',
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    srcCol: {
        width: "1.5in",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#000',
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 4,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    },


    valueTable: {
        display: "flex",
        width: "auto",
    },
    valueTableRow: {
        margin: "auto",
        flexDirection: "row",
        width: '100%',
        borderColor: 'red',
    },
    valueTableCell: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 0,
        borderRightWidth: 0,
        fontWeight: 400,
        padding: 3,
        flexGrow: 1,
        flexShrink: 1,
        width: 0,
    },
    valueTableHeader: {
        borderStyle: 'solid',
        fontWeight: 800,
        borderLeftWidth: 1,
        padding: 3,
        flexGrow: 1,
        flexShrink: 1,
        width: 0,
    },
    valueTableHeader1st: {
        borderLeftWidth: 0
    },
    valueTableCell1st: {
        borderLeftWidth: 0
    },
    valueLabel: {
        // fontWeight: 800,
        fontStyle: 'italic',
        padding: '4 4 0 4',
    },
    textValue: {
        padding: '2 4'
    },
});

export type AbstractDocProps = {
    docs: DoculyDoc[];
    tenantName?: string;
    address?: string;
}

type AbstractableInfo = {
    document: DoculyDoc,
    fieldMap: Record<string, AggregatedField>;
    fieldGroups: any[];
}

export function AbstractWithHtml({docs, tenantName, address}: AbstractDocProps) {

    const isMultiDoc = docs.length > 1;
    // todo: this needs a shit ton of clean up
    const info = useMemo<AbstractableInfo[]>(() => {

        return docs.map(document => {
            const fieldMap = groupAndPrepareFields(document);
            const docConfig = window.docTypeConfigs.get(document.docType);
            let fieldGroups;

            if (docConfig?.layout.length) {
                // use explicit layout
                fieldGroups = docConfig.layout;
            } else if (docConfig?.fieldOrder.length) {
                // produce layout from field order (todo: this might go away)
                // todo: fix keys so they're always snake-case in the db and ui, not sure why it was changed
                fieldGroups = [{fields: docConfig.fieldOrder.map(key => snakeCase(key))}]
            } else {
                // produce a default alphabetical listing of values
                fieldGroups = [{fields: [Object.keys(fieldMap).sort()]}]
            }

            // remove groups with no matching fields in the current document
            fieldGroups = fieldGroups.filter(({fields}) => fields.some(key => fieldMap[snakeCase(key)]));

            return {
                document,
                fieldGroups,
                fieldMap
            };
        });
    }, [docs]);
}

// Create Document Component
export function AbstractPdf({docs, tenantName, address}: AbstractDocProps) {

    const isMultiDoc = docs.length > 1;
    // todo: this needs a shit ton of clean up
    const info = useMemo<AbstractableInfo[]>(() => {

        return docs.map(document => {
            const fieldMap = groupAndPrepareFields(document);
            const docConfig = window.docTypeConfigs.get(document.docType);
            let fieldGroups;

            if (docConfig?.layout.length) {
                // use explicit layout
                fieldGroups = docConfig.layout;
            } else if (docConfig?.fieldOrder.length) {
                // produce layout from field order (todo: this might go away)
                // todo: fix keys so they're always snake-case in the db and ui, not sure why it was changed
                fieldGroups = [{fields: docConfig.fieldOrder.map(key => snakeCase(key))}]
            } else {
                // produce a default alphabetical listing of values
                fieldGroups = [{fields: [Object.keys(fieldMap).sort()]}]
            }

            // remove groups with no matching fields in the current document
            fieldGroups = fieldGroups.filter(({fields}) => fields.some(key => fieldMap[snakeCase(key)]));

            return {
                document,
                fieldGroups,
                fieldMap
            };
        });
    }, [docs]);


    return (
        <Document>
            <Page style={styles.page}>
                <DoculyMark/>
                <View style={styles.section}>
                    <Text style={styles.pageTitle}>Lease Abstract</Text>
                    {tenantName && <Text>{tenantName}</Text>}
                    {address && <Text>{address}</Text>}
                </View>

                {info.map(({document, fieldGroups, fieldMap}) => <>

                    {isMultiDoc && <View style={styles.section}>
                        <Text>{document.filename}</Text>
                    </View>}
                    {fieldGroups.map(group => (
                        <View style={styles.section}>
                            {group.label && <View style={styles.sectionLabel}>
                                <Text>{group.label}</Text>
                            </View>}
                            <View style={styles.table}>
                                {group.fields.map((key, index) => (
                                    <AbstractRow key={index} field={fieldMap[snakeCase(key)]}/>
                                ))}
                            </View>
                        </View>))}
                </>)}
                <Disclaimer/>
            </Page>
        </Document>
    );
}

type AbstractRowProps = {
    field: AggregatedField | undefined;
}

function AbstractRow({field}: AbstractRowProps) {

    if (!field) return <></>
    const pages = field.values.map(value => value.srcPositions?.map(p => p.page + 1)).flat();

    return (
        <View style={styles.tableRow} wrap={false}>
            <View style={styles.labelCol}>
                <Text>{field.displayName}</Text>
            </View>
            <View style={styles.valueCol}>
                {field.config?.table === true
                    ? <AbstractTableValue fields={field.values as any}
                                          config={field.config}/>
                    : field.values.length > 1
                        ?
                        <AbstractMultiValue fields={field.values as any} config={field.config}/>
                        : <AbstractSingleValue field={field.values[0] as any} config={field.config}/>
                }
            </View>
            {!field.config?.table && <View style={styles.srcCol}>
                <Text>{Boolean(pages.length) && `Page${pages.length > 1 ? 's' : ''} ${pages.join(', ')}`}</Text>
            </View>}
        </View>
    );
}

type AbstractTableValueProps = {
    fields: DoculyField[];
    config: FieldConfig;
}

function AbstractTableValue({fields, config}: AbstractTableValueProps) {

    const {columns, format: formats = {}} = config;

    return <View style={styles.valueTable}>
        <View style={styles.valueTableRow}>
            {columns.map((column, i) => {
                let style = styles.valueTableHeader;
                if (i === 0) {
                    style = {
                        ...style,
                        ...styles.valueTableHeader1st
                    }
                }

                return <View style={style} wrap={false} key={i}>
                    <Text wrap={false}>{capitalCase(column)}</Text>
                </View>
            })}
        </View>
        {fields?.map?.(field => (
            <View style={styles.valueTableRow}>
                {columns.map((column, i) => {
                    const key = camelCase(column);
                    const format = formats[key] ?? formats[column];
                    const value = formatValue(field.value[key], format)
                    // todo: find the actual type for ReactPDF.Style
                    let style: Style = styles.valueTableCell;
                    if (i === 0) {
                        style = {
                            ...style,
                            ...styles.valueTableCell1st
                        }
                    }
                    if (['money', 'number'].includes(format)) {
                        style = {
                            ...style,
                            justifyContent: 'flex-end',
                            textAlign: 'right'
                        }
                    }

                    return <View style={style} wrap={false} key={i}>
                        <Text wrap={false}>{value}</Text>
                    </View>
                })}
            </View>
        ))}
    </View>;
}

type AbstractMultiValueProps = {
    fields: DoculyField[];
    config?: FieldConfig;
}

function AbstractMultiValue({fields, config}: AbstractMultiValueProps) {

    return <>
        {fields.map((field, i) => (
            <AbstractSingleValue key={i} field={field} config={config}/>
        ))}
    </>
}

type AbstractSingleValueProps = {
    field: DoculyField;
    config?: FieldConfig;
}

function AbstractSingleValue({field, config}: AbstractSingleValueProps) {

    if (['string', 'number'].includes(typeof field.value)) {
        return <Text wrap style={styles.textValue}>{formatValue(field.value, config?.format)}</Text>
    }

    return <AbstractObjectValue field={field}/>
}

function AbstractObjectValue({field}: AbstractSingleValueProps) {

    const keys = useMemo<string[]>(() => {
        return Object.keys(field.value).sort();
    }, [field]);
    return <>
        {keys.map(key => <>
            <Text wrap style={styles.valueLabel}>{capitalCase(key)}</Text>
            <Text wrap style={styles.textValue}>{field.value[key]}</Text>
        </>)}
    </>
}

function DoculyMark() {

    return <View fixed style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: "center",
        position: 'absolute',
        bottom: '.25in',
        left: '.25in',
        right: '.25in',
    }}>
        <Svg width=".25in" height=".25in" viewBox="0 0 56 56" style={{marginRight: '.125in'}}>
            <Path style={{fill: '#149cff'}}
                  d="m0,24.6c0-2.89,2.34-5.23,5.23-5.23h6.79c2.89,0,5.23,2.34,5.23,5.23v12.28H5.23c-2.89,0-5.23-2.34-5.23-5.23v-7.05Z"/>
            <Path style={{fill: '#149cff'}}
                  d="m22.47,1.79c-2.89,0-5.23,2.34-5.23,5.23v7.05c0,2.89,2.34,5.23,5.23,5.23h6.79c2.84,0,5.14,2.3,5.14,5.14v7.14c0,2.84-2.3,5.14-5.14,5.14h-12.02v12.28c0,2.89,2.34,5.23,5.23,5.23h7.4c14.43,0,26.13-11.78,26.13-26.21S44.3,1.79,29.87,1.79h-7.4Z"/>
            <Path style={{fill: '#149cff', fillRule: 'evenodd'}}
                  d="m17.24,41.59s0-.09,0-.13c0-2.53-2.03-4.57-4.53-4.57h4.53v4.7h0Zm-4.57-4.7h0s.04,0,.04,0c-.01,0-.03,0-.04,0Z"/>
            <Path style={{fill: '#149cff', fillRule: 'evenodd'}}
                  d="m17.24,36.71h4.57-.04c-2.5,0-4.53-2.05-4.53-4.57,0-.04,0-.09,0-.13h0v4.7Z"/>
        </Svg>
        <Text>Lease Abstract generated by Doculy AI. https://doculy.ai</Text>
    </View>
}

function Disclaimer() {
    return <View style={{display: 'flex', flexDirection: 'row', marginTop: '.25in', width: '100%'}}>
        <View style={{}}>
            <Text>Note:</Text>
        </View>
        <View style={{flexGrow: 1, flexShrink: 1, width: 0, paddingLeft: '.125in'}}>
            <Text>
                This lease abstract does not represent the actual lease agreement. The lease agreement should be
                consulted for the purpose of verification of terms and provisions.
            </Text>
        </View>
    </View>
}
