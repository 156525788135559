import * as Guards from './guards';
import React, {Children, forwardRef, ReactElement, useImperativeHandle} from 'react';
import {Navigate} from 'react-router-dom';
import {SignUp} from "../components/SignUp";
import {SignIn} from "../components/SignIn";
import {InvitationViewer} from "../components/InvitationViewer";
import {Authentication} from "../views/Authentication";
import {useAppContext} from "@/contexts";
import {Dashboard} from "../views/Dashboard";
import {DocumentList} from "@/components/DocumentList";
import {InteractiveChat} from "@/components/InteractiveChat";
import {ExtractReviewer} from "@/components/ExtractReviewer";
import {PendingReviewList} from "@/components/PendingReviewList";
import {VerifyEmail} from "@/components/VerifyEmail";
import {PendingEmailVerification} from "@/components/PendingEmailVerification";
import {TenantSetup} from "@/components/TenantSetup";
import {User} from 'firebase/auth';
import {PendingApproval} from "@/components/PendingApproval";

// todo: move this to build variable so it can vary by environment
const DEFAULT_PAGE_TITLE = 'Doculy AI';

type RouteMetaData = {
    requireAuth?: boolean;
    requireTenant?: boolean;
    [key: string]: unknown;
};

type Guard = (args: GuardArgs) => string | void;

type ElementRouteConfig = {
    path: string;
    element: JSX.Element;
    alias?: string;
    aliases?: string[];
    guard?: Guard;
    children?: RouteConfig[]
    default?: true;
    meta?: RouteMetaData;
    title?: string;
    redirect?: never;
}

type RedirectRouteConfig = {
    path: string;
    redirect: string;
    element?: never;
    guard?: Guard;
}

type RouteGuardArgs = {
    guard?: Guard;
    element: ReactElement;
    [key: string]: any;
}

type RouteConfig = ElementRouteConfig | RedirectRouteConfig;

export type GuardArgs = {
    meta: RouteMetaData;
    user?: DoculyUser;
    tenant?: DoculyTenant;
    fbUser?: User | null;
}

const routeConfigs: RouteConfig[] = [
    {
        path: '/auth',
        element: <Authentication/>,
        guard: Guards.authentication,
        meta: {requireAuth: false},
        children: [
            {path: 'sign_up', element: <SignUp/>},
            {path: 'sign_in', element: <SignIn/>, default: true},
            {path: 'invitation/:invitationId', element: <InvitationViewer/>},
        ],
    }, {
        path: '/verify_account/:uid',
        guard: Guards.verifyEmail,
        element: <VerifyEmail/>
    }, {
        path: '/pending_email_verification',
        guard: Guards.pendingEmailVerification,
        element: <PendingEmailVerification/>
    }, {
        path: '/get_started',
        guard: Guards.getStarted,
        element: <TenantSetup/>
    }, {
        path: '/pending_approval',
        guard: Guards.pendingApproval,
        element: <PendingApproval/>
    }, {
        path: '/dashboard',
        element: <Dashboard/>,
        guard: Guards.dashboard,
        meta: {
            requireAuth: true
        },
        children: [
            {path: 'documents/:type?', element: <DocumentList/>, default: true},
            {path: 'chat/:id?', element: <InteractiveChat/>},
            {path: 'pending_reviews', element: <PendingReviewList/>},
            {path: 'review/:id', element: <ExtractReviewer/>},
            {path: 'extract/:id', element: <ExtractReviewer/>},
        ],
        default: true
    }
];

// @ts-ignore
export const AppRoute = forwardRef(({children, route, ...props}, ref) => {
    document.title = route.title;
    useImperativeHandle(ref, () => ({
        route,
        component: route.element.type,
    }));

    return Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        } else {
            return child;
        }
    });
});
AppRoute.displayName = 'AppRoute';

export function toRoute(config: RouteConfig, parent?: ElementRouteConfig): RouteConfig[] {
    let {path, element, aliases = [], children, meta = {}, guard, redirect, ...options} = config as ElementRouteConfig;

    (config as ElementRouteConfig).title = (config as ElementRouteConfig).title ?? parent?.title ?? DEFAULT_PAGE_TITLE;

    const paths: any[] = [];

    if (redirect) {
        return [{path: path, element: <Navigate to={redirect} replace/>}];
    }

    // if this a default path, make "*" and "" aliases
    if (options.default) {
        aliases.push('*', '');
    }

    // create a redirect for each alias
    // todo: make this work for non leaf paths
    aliases.forEach((alias) => paths.push({
        path: alias,
        // we need to remove the path variable from the redirect
        element: <Navigate to={path.replace(/\/:.*$/, '')} replace/>
    }));

    if (guard || meta?.requireAuth) {
        element = <RouteGuard guard={guard} element={element} meta={meta}/>;
    }

    // @ts-ignore
    element = <AppRoute route={config}>{element}</AppRoute>;

    return [...paths, {
        path,
        element,
        children: children?.map((child) => toRoute(child, config as ElementRouteConfig)).flat()
    }];
}


export function RouteGuard({guard, element, meta = {}, ...props}: RouteGuardArgs) {
    const {user, tenant, fbUser} = useAppContext();

    const redirect = guard?.({meta, user, tenant, fbUser});
    if (redirect) {
        return <Navigate to={redirect} replace/>;
    }

    return React.cloneElement(element, props);
}

RouteGuard.displayName = 'RouteGuard';

export const routes = routeConfigs.map(route => toRoute(route)).flat();
