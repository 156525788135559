import './DoculyAnswer.scss';
import React, {useMemo} from "react";
import classnames from "classnames";
import {DoculyResult} from "@/api";
import {AgGridReact} from "@ag-grid-community/react";
import DocumentListDetail from "@/components/DocumentList/DocumentListDetail.tsx";
import {ColDef} from "@ag-grid-community/core";
import {DocumentListMenuCellRenderer} from "@/components/DocumentList";

export interface DoculyAnswerProps extends React.HTMLAttributes<HTMLDivElement> {
    response: DoculyResult;
    dyRef?: React.Ref<HTMLDivElement>;
}

const baseClassName = 'doculy-answer';
const defaultColDef: ColDef = {
    suppressHeaderContextMenu: true
};

export function DoculyAnswer({className, response, dyRef, ...props}: DoculyAnswerProps) {

    const components = useMemo(() => ({
        menuCellRenderer: DocumentListMenuCellRenderer
    }), []);
    const colDefs = useMemo<ColDef[]>(() => [
        {
            cellRenderer: "agGroupCellRenderer", width: 40,
            cellClass: `${baseClassName}__trigger-col`,
            headerClass: `${baseClassName}__trigger-col`,
            sortable: false, pinned: 'left'
        },
        {
            field: "id",
            headerName: "ID",
            width: 50,
            suppressHeaderContextMenu: true,
            // cellClass: `${baseClassName}__1st-col`,
            // headerClass: `${baseClassName}__1st-col`
        },
        {field: "docType", headerName: "Type"},
        {field: "fields.customer_name.rawValue", headerName: 'Company'},
        {field: "filename", headerName: 'File Name', flex: 1},
        {headerName: 'Signature Date'},
        {field: "updatedAt", headerName: 'Last Update', cellDataType: 'date'},
        {
            headerName: 'Uploaded By',
            valueGetter({data}) {
                return data.createdBy ? `${data.createdBy?.firstName} ${data.createdBy?.lastName}` : 'anonymous'
            }
        },
        {
            cellRenderer: "menuCellRenderer",
            width: 40,
            cellClass: `${baseClassName}__menu-col`,
            headerClass: `${baseClassName}__menu-col`,
            sortable: false, pinned: 'right'
        },
    ], []);
    const hasDocs = Boolean(response.documents?.length);

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--with-docs`]: hasDocs
    })} {...props}>
        <div className={`${baseClassName}__message`} ref={dyRef}>
            <h1 className={`${baseClassName}__from`}>Doculy AI</h1>
            {response.answer ? (
                <div className={`${baseClassName}__text`}>
                    {response.answer}
                </div>
            ) : (<>
                {hasDocs && (
                    <div className={classnames(`${baseClassName}__documents`, 'ag-theme-quartz')}>
                        <AgGridReact
                            components={components}
                            detailRowAutoHeight={true}
                            domLayout={'autoHeight'}
                            suppressCellFocus={true}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            suppressContextMenu
                            rowData={response.documents}
                            masterDetail={true}
                            detailCellRenderer={DocumentListDetail}
                            defaultColDef={defaultColDef}
                            columnDefs={colDefs}/>
                    </div>
                )}
            </>)}
        </div>
    </div>
}


export default DoculyAnswer;
