import './bootstrap.ts';
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './styles/index.scss'
import {AppProvider, ServerEventsProvider} from "@/contexts";
import {BrowserRouter} from "react-router-dom";
import {MessagesProvider} from "@/contexts/MessagesContext.tsx";
import {DyModalContainer} from "@/components/core/DyModal";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {firebaseConfig} from "@/config";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <GoogleOAuthProvider clientId={firebaseConfig.googleClientId}>
            <ServerEventsProvider>
                <DyModalContainer>
                    <MessagesProvider>
                        <AppProvider>
                            <App/>
                        </AppProvider>
                    </MessagesProvider>
                </DyModalContainer>
            </ServerEventsProvider>
        </GoogleOAuthProvider>
    </BrowserRouter>,
)
