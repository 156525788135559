import './DyFileInput.scss';
import React from "react";
import classnames from "classnames";

export interface DyFileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
}


const baseClassName = 'dy-file-input';
const allowedFileTypes = 'PDF or .docx';

export function DyFileInput({className, ...props}: DyFileInputProps) {
    return <label className={classnames(baseClassName, className)}>
        <input type={"file"} {...props}/>
        <div><span className={`${baseClassName}__cta-text`}>Click To Upload</span> or drag and drop</div>
        <div>{allowedFileTypes}</div>
    </label>
}

export default DyFileInput;
