import './SignIn.scss';
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {DyButton, DyTextInput} from "../core";
import {GoogleAuthProvider, signInWithCredential, signInWithEmailAndPassword} from "firebase/auth";
import {DoculyHeader} from "@/components/DoculyHeader";
import {NavLink} from "react-router-dom";
import {useAppContext} from "@/contexts";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {tenantAuth} from "@/services/auth.service.ts";

export interface SignInProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'sign-in';

interface EmailAndPassword {
    email: string;
    password: string;
}

export function SignIn({className, ...props}: SignInProps) {

    const {loadingAccount} = useAppContext();
    const [busy, setBusy] = useState<boolean>(false);
    const [credentials, setCredentials] = useState<EmailAndPassword>({
        email: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState<ReactNode>('')
    const [invalid, setInvalid] = useState<boolean>(true);

    useEffect(() => {
        setInvalid(!(
            credentials.email.trim() &&
            credentials.password.trim()
        ));
    }, [credentials])

    function handleUserChange(e: ChangeEvent<HTMLInputElement>): void {
        setCredentials(user => ({
                ...user,
                [e.target.name]: e.target.value
            })
        )
    }

    async function handleGoogleSignIn(response: CredentialResponse) {
        const credential = GoogleAuthProvider.credential(response.credential);
        const {user} = await signInWithCredential(tenantAuth, credential);
    }

    async function handleLogin() {
        if (!invalid) {
            try {
                setBusy(true)
                setErrorMessage('');
                await signInWithEmailAndPassword(tenantAuth, credentials.email, credentials.password);
            } catch (err) {
                setErrorMessage(<>
                    <p>Hmm, that's not the right password. Please try again.</p>
                </>);
            } finally {
                setBusy(false);
            }
        }
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DoculyHeader className={`${baseClassName}__header`}/>
        <main className={`${baseClassName}__content`}>
            <GoogleLogin containerProps={{className: `${baseClassName}__google-btn`}} onSuccess={handleGoogleSignIn}
                         theme={"filled_blue"} shape={'rectangular'} width={'400'}/>
            <div className={`${baseClassName}__or`}>or</div>
            <div className={`${baseClassName}__credentials`}>
                <DyTextInput label={'Email'} name={'email'} onChange={handleUserChange} value={credentials.email}/>
                <DyTextInput label={'Password'} name={'password'} type="password"
                             onChange={handleUserChange} value={credentials.password}/>

            </div>
        </main>
        {Boolean(errorMessage) && (
            <div className={`${baseClassName}__error`}>{errorMessage}</div>
        )}
        <footer className={`${baseClassName}__actions`}>
            <DyButton onClick={handleLogin} disabled={invalid} busy={busy || loadingAccount}>Sign In</DyButton>
            <NavLink className={`${baseClassName}__mode-toggle`} to={'/auth/sign_up'} replace={true}>
                Don't have an account? Sign Up.
            </NavLink>
        </footer>
    </div>
}

export default SignIn;
