import './DyModal.scss';
import React, {ChangeEvent, Dispatch, ReactNode, RefObject, SetStateAction, useEffect, useRef, useState} from 'react';
import {create as createModal, hexGen, InstanceProps} from 'react-modal-promise';
import classnames from 'classnames';
import {isFunction} from 'lodash';
import {nanoid} from "nanoid";
import {DyButton, DyButtonProps, DyTextInput} from "@/components/core";

type AlertModalFunctionParams = {
    type?: 'error' | 'info' | 'default' | 'warning' | string;
    title?: ReactNode;
    content?: ReactNode;
    okLabel?: string;
    className?: string;
}
type AlertModalFunction<T = any> = (args: AlertModalFunctionParams) => Promise<void>
export const dyAlert: AlertModalFunction = createModal(
    ({onResolve, type, title, content, okLabel = 'Ok', className, ...props}: any) => {
        const wrapperClassNames = classnames('dy-modal-wrapper', 'dy-modal-wrapper--visible');

        const modalClasses = classnames(className, `dy-alert`, {
            [`dy-alert--${type}`]: Boolean(type),
        });
        return (
            <div className={wrapperClassNames}>
                <div className={modalClasses}>
                    {Boolean(title) && (
                        <header className={`dy-alert__header`}>
                            <h1>{title}</h1>
                        </header>
                    )}
                    <main className={'dy-alert__content'}>{content}</main>
                    <footer className={'dy-alert__footer'}>
                        <DyButton onClick={() => onResolve(true)} label={okLabel}/>
                    </footer>
                </div>
            </div>
        );
    });

/**
 *
 * @param headerType string
 * default: error
 * acceptedValues: ["error", "normal"]
 * what if we set header Type as error ?
 * If we set the headerType as error it will display the modal exclusively for errors UI
 * what if we set header Type as normal ?
 * If we set the headerType as normal it will display the modal layout as clean
 *
 * @param show boolean
 * default: true
 * if set to true, modal will pop up
 * if set to false, modal will hide
 *
 * @param size string
 * default: small
 * values: small and large
 * small defines pop up width and height for smaller content
 * large defines popup width and height for larger content
 */

type DyModalType = 'default' | 'error' | 'warning' | 'info' | string;
type DyModalProps = {
    onClick?: () => void;
    heading?: ReactNode;
    show?: boolean;
    headerType?: DyModalType
    contentType?: DyModalType
    children?: ReactNode;
    type?: DyModalType;
    onClose?: () => void;
    closeOnClickOutside?: boolean;
    closeButtonText?: string;
    size?: 'size' | string; // todo figure out the standard sizes
    extraIcon?: ReactNode;
    className?: string;
    showCloseOption?: boolean;
    footerContent?: ReactNode;
}

export function DyModal({
                            onClick,
                            heading,
                            show = false,
                            type = 'default',
                            headerType = type,
                            contentType = 'default', // this was set to equal 'type' but it was breaking the CSS of the modal. 'default' was pre-refactor value. research
                            children,
                            onClose = onClick,
                            closeOnClickOutside = false,
                            size = type,
                            extraIcon,
                            className = '',
                            showCloseOption = false,
                            footerContent,
                            ...props
                        }: DyModalProps) {

    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (show === true) document.body.style.position = 'absolute';
        else document.body.style.position = 'unset';
    }, [show]);

    const wrapperClassNames = classnames(className, {
        'dy-modal-wrapper': true,
        'dy-modal-wrapper--visible': show,
        [`dy-modal-wrapper--${type}`]: true,
    });

    const headerClassNames = classnames({
        [`dy-modal-header__${headerType}`]: true,
        [`dy-modal-header-br`]: Boolean(heading),
    });

    const modalClassNames = classnames({
        [`dy-modal--${size}`]: true,
    });

    const onClickOutside = (event) => {
        if (!ref.current?.contains(event.target)) {
            return onClose?.();
        }
    };

    return (
        <div className={wrapperClassNames} {...props} onClick={closeOnClickOutside ? onClickOutside : undefined}>
            <div className={`dy-modal ${modalClassNames}`} ref={ref}>
                <header className={`dy-modal-header ${headerClassNames}`}>
                    <h1>{heading}</h1>
                    {extraIcon && <div className={'dy-modal-header__extraIcon'}>{extraIcon}</div>}
                    {showCloseOption && <div className={`dy-modal-header-close`} onClick={onClose}/>}
                </header>
                <main
                    className={classnames({
                        ['dy-modal-content']: !contentType || contentType === 'default',
                        ['dy-modal-error-content']: contentType === 'error',
                        ['dy-modal-warning-content']: contentType === 'warning',
                    })}
                >
                    {children}
                </main>
                {footerContent && (
                    <>
                        <footer className={'dy-modal-footer'}>
                            {footerContent}
                        </footer>
                    </>
                )}
            </div>
        </div>
    );
}

export function DyModalMask({className, show, children, ...props}) {
    if (show) {
        return (
            <div className={classnames('dy-modal__loading-mask', className)} {...props}>
                <svg
                    className={'dy-modal__loading-spinner'}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
                {children && <div className={'dy-modal__loading-text'}>{children}</div>}
            </div>
        );
    }

    return <></>;
}

export type ModalContentParams<StateType = unknown, RefType = unknown> = {
    /**
     * The id passed to openModal or the value that was auto generated
     */
    id: string;
    /**
     * Causes the modal to close and its promise to resolve with passed value.
     * @param value
     */
    resolve(value?: unknown): void;
    /**
     * Causes the modal to close and its promise to reject with passed value.
     * @param value
     */
    reject(value?: unknown): void;
    /**
     * Causes the modal to close and the promise to resolve with the value false.
     */
    close(): void;
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Puts the modal into it's busy state
     */
    setBusy: Dispatch<SetStateAction<boolean>>;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: StateType;
    /**
     * React Dispatch function that will update the state of the modal's content.
     */
    setState: Dispatch<SetStateAction<StateType>>;
    /**
     *
     */
    ref: React.Ref<RefType>
}

export type ModalActionParams<StateType = unknown, RefType = unknown> = {
    /**
     * Causes the modal to close and its promise to resolve with passed value.
     * @param value
     */
    resolve(value?: unknown): void;
    /**
     * Causes the modal to close and its promise to reject with passed value.
     * @param value
     */
    reject(value?: unknown): void;
    /**
     * Causes the modal to close and the promise to resolve with the value false.
     */
    close(): void;
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Puts the modal into it's busy state
     */
    setBusy(isBusy: boolean): void;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: StateType;
    /**
     * React Dispatch function that will update the state of the modal's content.
     */
    setState: Dispatch<SetStateAction<StateType>>;
    /**
     * Ref object that allows the action to directly communicate with component(s) in the body
     */
    ref: RefObject<RefType>
}

export type ModalStateParams<T = unknown> = {
    /**
     * Indicates the modal is actively performing an action
     */
    busy: boolean;
    /**
     * Holds any state that needs to be maintained by the modal.  This allows a component to update it's state.
     */
    state: T;
}

export type ModalAction<T = unknown, RefType = unknown> = DyButtonProps & {
    key: string;
    disableIf?(params: ModalStateParams<T>): boolean;
    action(params: ModalActionParams<T, RefType>): void | Promise<void>;
}
export type OpenModalOptions<StateType = unknown, RefType = unknown> = {
    /**
     * Optional unique id for the modal. This can be used to explicitly close the popup later.  A nonoid will automatically
     * be generated if no id is specified
     */
    id?: string;
    content: ((args: ModalContentParams<StateType, RefType>) => ReactNode) | ReactNode;
    title?: ReactNode;
    actions?: ModalAction<StateType, RefType>[];
    initialState?: StateType;
    closable?: boolean;
} & Partial<Omit<DyModalProps, 'children'>>;

export function openModal<StateType = unknown, RefType=unknown>({id = nanoid(), ...props}: OpenModalOptions<StateType, RefType>): Promise<any> & {
    id: string;
    reject(): void;
    resolve(): void;
} {
    const promise = createModal<InstanceProps<StateType> & OpenModalOptions<StateType, RefType>>(
        ({
             isOpen,
             onResolve,
             onReject,
             close,
             instanceId,
             title,
             content,
             className,
             actions,
             initialState,
             size,
             closable = false,
             ...props
         }) => {

            const [busy, setBusy] = useState<boolean>(false);
            const [state, setState] = useState<StateType>(initialState as StateType);
            const ref = useRef<RefType>(null);

            return (
                <DyModal {...props} className={className}
                         heading={title}
                         show={isOpen}
                         showCloseOption={closable}
                         onClose={onResolve}
                         size={size ?? 'default'}
                         footerContent={actions?.map(({action, disableIf, ...props}) => (
                             <DyButton {...props}
                                       disabled={disableIf?.({busy, state})}
                                       onClick={() => action({
                                           close: onReject,
                                           resolve: onResolve,
                                           reject: onReject,
                                           busy,
                                           setBusy,
                                           state,
                                           setState,
                                           ref,
                                       })}/>
                         ))}>
                    {isFunction(content) ? content({
                        close,
                        busy,
                        setBusy,
                        state,
                        setState,
                        resolve: onResolve,
                        reject: onReject,
                        id: instanceId,
                        ref,
                    }) : content}
                </DyModal>
            );
        }
    )({
        instanceId: id,
        ...props,
    });

    (promise as any).id = id;
    (promise as any).resolve = () => {
        window.dyModals.resolve(id);
    };
    (promise as any).reject = () => {
        window.dyModals.reject(id);
    };
    return promise as any;
}

type ConfirmParams = {
    title?: string;
    content?: ReactNode;
    yesLabel?: string;
    noLabel?: string;
    closeButton?: boolean;
    className?: string;
    suppressed?: boolean;
    onSuppress?(): void;
    suppressable?: boolean;
    suppressMessage?: ReactNode;
}

export async function dyConfirm(params: ConfirmParams): Promise<boolean> {

    if (params.suppressed) return true;

    return createModal<ConfirmParams & InstanceProps<boolean>, boolean>(
        ({
             onResolve, title, content, yesLabel = 'Yes', noLabel = 'No', closeButton = false, className,
             suppressable, suppressed = false, onSuppress, suppressMessage = 'Do not show again.'
         }) => {

            const baseClassName = 'dy-confirm';
            const [suppress, setSuppress] = useState<boolean>(suppressed);
            //
            // function handleSuppressChange(e: ChangeEvent<HTMLInputElement>): void {
            //     setSuppress(e.target.checked);
            // }

            function handleYes(): void {

                if (suppress) {
                    onSuppress?.();
                }
                onResolve(true)
            }

            return (
                <div className={classnames('dy-modal-wrapper', 'dy-modal-wrapper--visible')}>
                    <div className={classnames(baseClassName, className)}>
                        {Boolean(title || closeButton) && (
                            <header className={classnames(`dy-confirm__header`, {
                                [`${baseClassName}__header--with-close`]: closeButton
                            })}>
                                <h1>{title}</h1>
                                {closeButton && (
                                    <div className={`${baseClassName}__header-close`} onClick={() => onResolve(false)}/>
                                )}
                            </header>
                        )}
                        <main className={`${baseClassName}__content`}>
                            {content}
                            {suppressable && <div className={`${baseClassName}__suppress`}>
                                {/*<DyCheckbox label={suppressMessage} onChange={handleSuppressChange}/>*/}
                            </div>}
                        </main>
                        <footer className={`${baseClassName}__footer`}>
                            <DyButton onClick={() => onResolve(false)} dyType="secondary">{noLabel}</DyButton>
                            <DyButton onClick={handleYes}>{yesLabel}</DyButton>
                        </footer>
                    </div>
                </div>
            );
        }
    )(params);
}


export function warn(message, {isTip = true, id = hexGen(), ...props} = {}) {
    const promise = createModal(
        ({isOpen, onResolve, title, content, className, message, size = 'small', isTip = true, ...props}: any) => {
            return (
                <DyModal show={isOpen} onClose={onResolve} size={size} headerType={'none'} contentType={'warning'}>
                    <div className={'dy-error-modal__container'}>
                        <span className={'dy-error-modal__container-text'}>
                            {isTip && <b>Tip:&nbsp;</b>}
                            <div className="dy-label">{message}</div>
                        </span>
                    </div>
                </DyModal>
            );
        }
    )({
        instanceId: id,
        message,
        isTip,
        ...props,
    });
}

export type PromptParams = {
    /**
     * Optional id used to close and reference the modal.
     */
    id?: string;
    /**
     * Optional title for the modal.
     */
    title?: ReactNode;
    /**
     * Content to display above the input.
     */
    content: ReactNode;
    /**
     Optionally overrides the text of the submit button.  Defaults to "Cancel".
     */
    cancelLabel?: string;
    /**
     * Optionally overrides the text of the submit button.  Defaults to "Ok".
     */
    submitLabel?: string;
    /**
     * Input's placeholder text
     */
    placeholder?: string;
}

export function dyPrompt({id = nanoid(), ...props}: PromptParams): Promise<any> & {
    id: string;
    reject(): void;
    resolve(): void;
} {
    const baseClassName = `dy-modal-prompt`;
    const promise = createModal<InstanceProps<string> & PromptParams>(
        ({isOpen, onResolve, onReject, cancelLabel = 'Cancel', submitLabel = 'Ok', title, content}) => {

            const [value, setValue] = useState<string>('');

            function handleSubmit() {
                onResolve(value);
            }

            function handleValueChange(e: ChangeEvent<HTMLInputElement>) {
                setValue(e.target.value);
            }

            return (
                <DyModal show={isOpen} heading={title} footerContent={<>
                    <DyButton onClick={() => onReject()} dyType={'secondary'} label={cancelLabel}/>
                    <DyButton onClick={handleSubmit} label={submitLabel}/>
                </>}>
                    {content}
                    <DyTextInput className={`${baseClassName}__input`} value={value} onChange={handleValueChange}/>
                </DyModal>
            );
        }
    )({
        instanceId: id,
        ...props,
    });

    (promise as any).id = id;

    (promise as any).resolve = () => {
        window.dyModals.resolve(id);
    };
    (promise as any).reject = () => {
        window.dyModals.reject(id);
    };
    return promise as any;
}

export const useModals = () => ({
    openModal,
    dyConfirm,
    dyAlert,
});
