import {webPushConfig} from "@/config/webPush.ts";
import {addWebPushSubscription} from "@/api";

export async function subscribeToNotifications(): Promise<PushSubscription | undefined> {
    try {
        if (Notification.permission !== 'granted') return;

        const registration = await navigator.serviceWorker.ready;
        const subscription: PushSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: webPushConfig.applicationServerKey
        });

        await addWebPushSubscription(subscription);

        return ;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
